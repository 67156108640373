import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "con" }
const _hoisted_2 = { class: "btn" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mas_modal = _resolveComponent("mas-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_mas_modal, {
      visible: _ctx.selectMobileModalState,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectMobileModalState) = $event)),
      title: "选择外呼号码",
      customClass: "hbb-select-call-mobile-modal",
      width: 400,
      closeOnClickModal: false,
      customFooterBtns: "",
      closeIcon: ""
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialMobile(_ctx.currentActiveObj.firstMobileNumber, _ctx.currentActiveObj, 1)))
            }, [
              (!_ctx.currentActiveObj.mobileHideState)
                ? (_openBlock(), _createElementBlock("b", _hoisted_3, _toDisplayString(_ctx.currentActiveObj.callState
                    ? _ctx.currentActiveObj.firstMobileNumber
                    : _ctx.setEncryptionPhoneText(_ctx.currentActiveObj.firstMobileNumber)), 1))
                : (_openBlock(), _createElementBlock("b", _hoisted_4, _toDisplayString(_ctx.currentActiveObj.firstMobileNumber), 1))
            ]),
            _createElementVNode("span", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (
                _ctx.dialMobile(_ctx.currentActiveObj.secondMobileNumber, _ctx.currentActiveObj, 0)
              ))
            }, _toDisplayString(_ctx.currentActiveObj.secondMobileNumber || "--"), 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}