import environment from "@@/environment";

import { buildInitialVuePlugin } from "@velites/init";
import { pcSharedComponentsVuePlugin } from "@ldos/shared-pc";
import { dynamicInitialVuePluginOptionsDecorators } from "@ldos/shared-common";
import { allWidgetVueInitPlugin } from "./widget";
import { wsInitPluginExtension } from "./ws";
import { mgrExtraStoresRegistryModule } from "./store";
import { wecomInitialVuePluginOptionsDecorators } from "./wechat";
import { miscVueInitPlugin } from "./misc";
import './boot';
import router from "../router";

import "./style";
import { mgrControllerRegistryModule } from "./controller";
const stubs = buildInitialVuePlugin(
  {
    env: environment,
    router,
    moreInjectionModules: [mgrExtraStoresRegistryModule, mgrControllerRegistryModule],
    moreExtensions: [wsInitPluginExtension],
    moreVuePlugins: [
      allWidgetVueInitPlugin,
      pcSharedComponentsVuePlugin,
      miscVueInitPlugin
    ]
  },
  dynamicInitialVuePluginOptionsDecorators,
  wecomInitialVuePluginOptionsDecorators
);
export default stubs;
