export default [
    {
        // 没有任何路由权限的时候, 跳转至无默认的权限的路由
        path: '/noRouterView',
        component: () => import("@/views/config/index.vue"),
        meta: {
            title: '该账号暂无路由权限',
            permissionCode: "noRouterViewPermission", // 乱写的权限以保证页面展示无页面权限
        }
    },
]