export class verification {
  // 是否身份证号码
  public static identityId = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  // 是否手机号码或者固话
  public static telephone = /^((0\d{2,3}-\d{7,8})|(1[345678]\d{9}))$/;
  // 是否邮箱
  public static mailbox = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
  // 整数填写
  public static integer = /^-?[1-9]\d*$/;
  // 正整数填写
  public static positiveInteger = /^[1-9]\d*$/;
  // 11或者12位的数字
  public static commonMobile = /^(\d{11}|\d{12})$/;
  // 小写字母
  public static lowercaseLetters = /^[a-z]+$/;
  // 大写字母
  public static capital = /^[A-Z]+$/;
  // 大小写字母混合
  public static caseMixing = /^[A-Za-z]+$/;
  // 数字加字母 (不包含特殊化符合) 1111@1111
  public static emoveSpecialSymbols = /^[a-zA-Z0-9]+$/;
  // 只能输入数字和一个小数点的正则表达式
  public static numberText = /^(\-|\+)?\d+(\.\d+)?$/g;
  // 校验输入是否为中文
  public static chinese = /^[\u0391-\uFFE5A-Za-z]+$/;
  // 校验密码输入可以指定某些特殊字符;@%&#
  public static haveSpecialStr = /^[@%&#a-zA-Z0-9]+$/;
  public static passSpecialStr = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,16}$/;

  /**
   * 检测关键字高亮
   * @param content
   * @param keyWords <string | array>
   * @param color
   * @returns
   */
  public static detectionKeyword(
    content: string,
    keyWords: any,
    color: string
  ) {
    let str = content;
    if (typeof keyWords == "string") {
      // 字符串模式
      if (!keyWords) return content;
      str = content.replace(
        new RegExp(keyWords, "g"),
        `<span class="hbb" style="color:${color};">${keyWords}</span>`
      );
    } else {
      // 数组模式
      if (content && content !== "") {
        // 2.定制关键词对应正则
        keyWords.forEach((e: string, i: number) => {
          const regStr = e;
          const reg = new RegExp(regStr, "g");
          // 3.正则替换，关键词飘红
          str = str.replace(
            reg,
            `<span style="color:${color};">` + e + "</span>"
          );
        });
      }
    }
    return str;
  }
  /**
   *
   * @param rule
   * @param value
   * @param callback
   */
  public static validatePassword(
    rule: any,
    value: any,
    callback: (arg0: Error | undefined) => void
  ) {
    let reg = /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{6,12}$/;
    if (!reg.test(value)) {
      callback(new Error("密码应是6-12位数字、字母或字符！"));
    } else {
      callback(undefined);
    }
  }
}
