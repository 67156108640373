
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 30
    },
    width: {
      type: Number,
      default: 400
    },
    rotateIcon: {
      type: Boolean,
      default: false
    },
    trigger: {
      type: String,
      default: 'hover'
    }
  },
  data() {
    return {
      clientHeight: 0
    }
  },
  mounted() {
    this.clientHeight = (this.$refs.tipBoxCell as Element).clientHeight;
  }
});
