import lodash from "lodash";
import moment from "moment";
import { ConstructorOf, Nullable } from "@velites/common";
import _ from "lodash";

// 数字转化 时分秒 例子： 10: 00:00:10
export const conversionTimeFormat = (n: number | string): string => {
  let str = "";
  let time = moment.duration(Number(n), "seconds");
  let hours = time.hours();
  let minutes = time.minutes();
  let seconds = time.seconds();
  str = moment({ h: hours, m: minutes, s: seconds }).format("HH:mm:ss");
  return str;
};

export const initOptions = (filters: any, pockets: any) => {
  // 页面option与pockets进行关联
  if (!pockets) return [];
  let arr: any[] = [];
  filters.forEach((x: any) => {
    if (pockets[x.name]) x.options = pockets[x.name];
    arr.push(x);
  });
  return arr || [];
};

export const initOptionsChildren = (arr: any) => {
  // 转化  extra 为 children
  arr.forEach((x: any) => {
    if (Array.isArray(x.extra)) {
      if (x.extra?.length) {
        x.children = x.extra;
        if (x.children?.length) {
          initOptionsChildren(x.extra);
        }
      }
    } else {
      if (x.extra && x.extra.children?.length) {
        x.children = x.extra.children;
        if (x.children?.length) {
          initOptionsChildren(x.children);
        }
      }
    }
  });
  return arr || [];
};
export const filterFormOption = (formList: any[], key: string) => {
  let obj: any = {
    options: []
  };
  if (key) {
    obj = formList.filter((x: any) => x.name === key)[0];
  }

  return obj;
};

// 部分table内部字段根据pockets相关配置项进行展示
export const setFiltersPocketsText = (filters: any, t: string, s: string) => {
  let str = "";
  if (Array.isArray(filters)) {
    filters.forEach((x: any) => {
      if (x.name === t)
        str = x.options?.filter((x: any) => x.value === s)[0]?.label;
    });
  } else {
    Object.keys(filters).forEach((key: string) => {
      if (filters[t]) {
        str = filters[t].filter((x: any) => x.value === s)[0]?.label;
      }
    });
  }
  return str || "--";
};
// 处理时间戳转化年月日时分秒
export const timeFormat = (timestamp: number, type: number = 0) => {
  if (!timestamp) return "";
  let formartText = type ? "YYYY/MM/DD" : "YYYY/MM/DD HH:mm:ss";
  return moment(new Date(timestamp)).format(formartText);
};
// 请求时间参数的时间格式
export const requestTimeFormat = (str: string) => {
  return str ? Number(new Date(str).getTime()) : null;
};
// 请求时间参数的时间格式 时间范围
export const requestStartEndTimeFormat = (str: string[]) => {
  if (str) {
    return {
      startTime: Number(str[0]),
      endTime: Number(str[1])
    };
  } else {
    return null;
  }
};

export const defaultDateFormat = "YYYY/MM/DD";
export const defaultDateTimeFormat = "YYYY/MM/DD HH:mm:ss";
export type TimeType = Nullable<string | number | Date>;
export type TimeTypeConstructor =
  | ConstructorOf<String>
  | ConstructorOf<Number>
  | ConstructorOf<Date>;
/**
 *
 * @param time
 * @param format use {@link defaultDateFormat} as default
 * @returns
 */
export const formatTimeToDate = (time: TimeType, format?: string) => {
  if (time != null) {
    if (time instanceof Date) {
      return time;
    }
    return moment(time, format || defaultDateFormat).toDate();
  }
};
/**
 *
 * @param time
 * @param format use {@link defaultDateFormat} as default
 * @returns
 */
export const formatTimeToString = (time: TimeType, format?: string) => {
  if (time != null) {
    if (lodash.isString(time)) {
      return time;
    }
    return moment(time).format(format || defaultDateFormat);
  }
};
/**
 *
 * @param time
 * @param format use {@link defaultDateFormat} as default
 * @returns
 */
export const formatTimeToStamp = (time: TimeType, format?: string) => {
  if (time != null) {
    if (lodash.isNumber(time)) {
      return time;
    }
    return moment(time, format || defaultDateFormat).valueOf();
  }
};
export const formatTimeToSpecifiedType = (
  time: TimeType,
  type: TimeTypeConstructor,
  format?: string
) => {
  switch (type) {
    case Number:
      return formatTimeToStamp(time, format);
    case String:
      return formatTimeToString(time, format);
    case Date:
      return formatTimeToDate(time, format);
  }
};
export const inferTimeType = (
  time: TimeType,
  defaultType: TimeTypeConstructor = String
) => {
  return <TimeTypeConstructor>time?.constructor || defaultType;
};

// 处理手机号加密
export const handleMaterialPhone = (row: any, key: string) => {
  let str = "";
  if (!row.mobileHideState) {
    str = setEncryptionPhone(row[key]);
  } else {
    str = row[key];
  }
  return str;
};
// 控制最多n个字符
export const contentTextLength = (
  str: string | null | undefined,
  len: number,
  spot: boolean = true
) => {
  if (!str) return "--";
  let text = "";
  let spotText = spot ? "..." : "";
  if (str.length > len) {
    text = str.substring(0, len) + spotText;
  } else {
    text = str;
  }
  return text;
};

export const enumerateDaysBetweenDates = (startDate: any, endDate: any) => {
  // 取两个时间段之间的所有日期
  let daysList = [];
  const start = moment(startDate);
  const end = moment(endDate);
  const day = end.diff(start, "days");
  // DDDD
  daysList.push(start.format("YYYY-MM-DD"));
  for (let i = 1; i <= day; i++) {
    daysList.push(start.add(1, "days").format("YYYY-MM-DD"));
  }
  return daysList;
};
// 下载文件流
export const downloadByteArraystream = (res: any) => {
  let type = "application/octet-stream";
  let blob = new Blob([res.data], { type: type });
  let disposition = res.headers["content-disposition"];
  let fileName = "";
  try {
    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, "");
    }
    fileName = decodeURI(fileName).replace("utf-8", "");
  } catch (e) {
    fileName = `file-${new Date().getTime()}`;
  }
  let URL = window.URL || window.webkitURL;
  let objectUrl = URL.createObjectURL(blob);
  var aLink = document.createElement("a");
  aLink.href = objectUrl;
  aLink.download = fileName;
  document.body.appendChild(aLink);
  aLink.click();
  aLink.remove();
};
//获取本地存储的数据
export function getLocalStorageValue(key: string) {
  const value = localStorage.getItem(key);
  if (!value) return null;
  try {
    return JSON.parse(value);
  } catch (error) {
    return null;
  }
}
//设置本地存储的数据
export function setLocalStorage(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function setEncryptionPhone(v: string) {
  // 处理手机号的加密
  if (!v) return "";
  return v.substr(0, 3) + "********";
}
export function requestEverySelectMultiple(obj: any, index: number = 0) {
  let selectArr: string[] = [];
  if (obj.length) {
    obj.forEach((x: any) => {
      if (x.constructor === Array) {
        selectArr.push(x[index].value);
      } else {
        selectArr.push(x.value);
      }
    });
  }
  return selectArr.length ? _.uniqWith(selectArr, _.isEqual) : [];
}
export const nonExistent = (v: any): boolean => {
  const nonExistent = [null, "null", undefined, "undefined", {}, "{}"];
  return _.includes(nonExistent, v);
};
