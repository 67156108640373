
import {
  ComponentInternalInstance,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  SetupContext,
  toRefs,
  watch
} from "vue";

export interface ITabItem {
  label: string;
  value: string;
  name?: string;
  count?: number;
  checked?: boolean;
}

interface IState {
  activeTab: string;
  formatList: ITabItem[];
}

export default defineComponent({
  name: "TabBar",
  props: {
    tabList: {
      type: Array as () => ITabItem[],
      required: true
    },
    current: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context: SetupContext) {
    const { proxy } = getCurrentInstance() as ComponentInternalInstance;
    const state: IState = reactive({
      activeTab: props.current,
      formatList: []
    });

    const formatOptions = () => {
      state.formatList = props.tabList
        ? props.tabList.map(({ label, value, count, name }: ITabItem) => {
          const stateStr = typeof count === "number" ? `（${count}）` : "";
          return {
            label: `${label}${stateStr}`,
            value,
            name: value
          };
        })
        : [];
    };

    onMounted(() => {
      formatOptions();
    }),
      watch(props, (newProps) => {
        setActiveTab(newProps.current);
        formatOptions();
      });

    const setActiveTab = (str: string) => {
      state.activeTab = str;
    };

    const handleClick = (args: any) => {
      const { index } = args;
      const tabItem = props.tabList[index];
      proxy?.$emit("handleClick", tabItem);
    };

    return {
      handleClick,
      ...toRefs(state)
    };
  }
});
