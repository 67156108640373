import { Plugin } from "vue";
import "element-plus/dist/index.css";
import {
  ElButton,
  ElInput,
  ElRadioGroup,
  ElRadioButton,
  ElMenu,
  ElContainer,
  ElIcon,
  ElAside,
  ElCheckbox,
  ElBreadcrumb,
  ElForm,
  ElSlider,
  ElMenuItem,
  ElFormItem,
  ElMenuItemGroup,
  ElSubMenu,
  ElBreadcrumbItem,
  ElDialog,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElMessage,
  ElNotification,
  ElSelect,
  ElOption,
  ElPopover,
  ElDatePicker,
  ElConfigProvider,
  ElCalendar,
  ElButtonGroup,
  ElTable,
  ElTag,
  ElCheckTag,
  ElTooltip,
  ElTableColumn,
  ElPagination,
  ElTabs,
  ElTabPane,
  ElEmpty,
  ElSkeleton,
  ElCheckboxGroup,
  ElCascader,
  ElRadio,
  ElUpload,
  ElPopconfirm,
  ElProgress,
  ElCollapse,
  ElCollapseItem,
  ElSwitch
} from "element-plus";
export const elementPlusWidgetPlugins = <Plugin[]>[
  ElMenu,
  ElBreadcrumb,
  ElForm,
  ElRadioGroup,
  ElCascader,
  ElRadioButton,
  ElEmpty,
  ElButton,
  ElSlider,
  ElContainer,
  ElAside,
  ElDialog,
  ElInput,
  ElIcon,
  ElCheckbox,
  ElFormItem,
  ElSubMenu,
  ElMenuItem,
  ElBreadcrumbItem,
  ElMenuItemGroup,
  ElSelect,
  ElOption,
  ElPopover,
  ElSkeleton,
  ElDatePicker,
  ElConfigProvider,
  ElCalendar,
  ElButtonGroup,
  ElTable,
  ElTag,
  ElCheckTag,
  ElTooltip,
  ElTableColumn,
  ElPagination,
  ElTabs,
  ElCheckboxGroup,
  ElTabPane,
  ElRadio,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElUpload,
  ElPopconfirm,
  ElProgress,
  ElCollapse,
  ElCollapseItem,
  ElSwitch
];
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
export default <Plugin>((app) => {
  elementPlusWidgetPlugins.forEach((c) => app.use(c));
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component as any);
  }
});
