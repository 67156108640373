import _ from "lodash";
// 数字转化 时分秒 例子： 10: 00:00:10
export const setFormRequired = (ruleForm: any, v: boolean) => {
  Object.keys(ruleForm).forEach((x) => {
    ruleForm[x].forEach((y: any) => {
      y.required = v ? false : true;
    });
  });
  return ruleForm;
};
