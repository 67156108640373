const parentMenuKey = "home";
export default [
  {
    meta: {
      permissionCode: "Mgr.Cockpit.RetailBusiness.View",
      title: "零售业务部大屏",
      parentMenuKey,
      truePath: '/home',
    },
    icon: "",
    path: "/home/home",
    component: () => import("@/views/config/views/home.vue")
  },
  {
    meta: {
      permissionCode: "Mgr.Cockpit.RetailBusiness.View",
      title: "大区大屏",
      parentMenuKey,
      truePath: '/region',
    },
    icon: "",
    path: "/home/region",
    component: () => import("@/views/config/views/home.vue")
  },
  {
    meta: {
      permissionCode: "Mgr.Cockpit.RetailBusiness.View",
      title: "分公司大屏",
      parentMenuKey,
      truePath: '/subsidiary',
    },
    icon: "",
    path: "/home/subsidiary",
    component: () => import("@/views/config/views/home.vue")
  },
];
