import {
  RequestParam,
  RequestMapping,
  RestController,
  PostMapping,
} from '@/common/decorators';
import { CockpitYearRegionVO, CockpitYearVO } from '../vo';
import { v4 as uuidv4 } from 'uuid';
import { generateRandomArray } from '@ldos/shared-common/src/util/mock';

const faker = require('faker');

function getCockpitYearVO(): CockpitYearVO {
  const year: string = faker.datatype.number({ min: 2022, max: 2023 }) + '';
  const regionCount: number = faker.datatype.number({ min: 1, max: 5 });
  const regions: CockpitYearRegionVO[] = [];

  for (let i = 0; i < regionCount; i++) {
    const region: CockpitYearRegionVO = {
      id: uuidv4(),
      name: faker.address.state(),
      subsidiary: [],
    };

    const subsidiaryCount: number = faker.datatype.number({ min: 1, max: 3 });
    for (let j = 0; j < subsidiaryCount; j++) {
      region.subsidiary.push({
        id: uuidv4(),
        name: faker.company.companyName(),
        regionId: region.id,
      });
    }

    regions.push(region);
  }

  return { year, region: regions, subsidiary: [] };
}

@RestController
@RequestMapping('/mgr/cockpit')
export default class CockpitController {
  @PostMapping('/year')
  async year() {
    return generateRandomArray(getCockpitYearVO, 1) as CockpitYearVO[];
  }
}
