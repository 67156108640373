
import { Options, Vue } from "vue-class-component";
@Options({
  props: {
    isHandle: {
      type: Boolean,
      default: true
    },
    labelWidth: {
      type: String,
      default: "80px"
    },
    width: {
      type: String,
      default: "200px"
    },
    size: {
      type: String,
      default: "mini"
    },
    rules: {
      type: Object,
      default: function () {
        return {};
      }
    },
    searchForm: {
      type: Array,
      default: []
    },
    searchHandle: {
      type: Array,
      default: () => []
    },
    searchData: {
      type: Object,
      default: function () {
        return {};
      }
    },
    limit: {
      type: Number
    },
    action: String,
    imgList: String,
    isShowRequired: {
      type: Boolean,
      default: true
    }
  }
})
export default class extends Vue {
  imgList!: [];
  searchForm!: [];

  mounted() {
    console.log(this.searchForm);
  }
}
