
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    ids: {
      type: Array as () => String[],
      require: true,
      default: () => []
    }
  }
})
export default class extends Vue {
  selectAll() {
    this.$emit("selectAll");
  }
  clearAll() {
    this.$emit("clearAll");
  }
}
