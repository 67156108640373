
import "@/icons/iconfont.js";
import { SimpleItem } from "@velites/common";
import { ElForm, ElMessage } from "element-plus";
import { reactive } from "vue";
import { Options, Vue } from "vue-class-component";
import { User } from "./user";
type FormInstance = InstanceType<typeof ElForm>;

@Options({
  emits: ["close"],
  props: {
    user: User,
  },
})
export default class extends Vue {
  step1Flag: boolean = true;
  step2Flag: boolean = false;
  step3Flag: boolean = false;
  disableParam: MgrUserDisableBO = new MgrUserDisableBO();
  shopUserArray: Array<Object> = [];

  get user(): User {
    return (this.$props as any).user;
  }

  rules = reactive({
    // toTransferUserId: [
    //   {
    //     required: true,
    //     message: "请选择移交用户",
    //     trigger: "blur",
    //   },
    // ],
  });
  step1() {
    const t = this as any;
    const user: User = t.user;
    if ((this?.$stores as any).store.state.CommonStore.terminal === "ops") {
      var api = "/ops/userTenant/remove";
    } else {
      var api = "/mgr/userTenant/remove";
    }
    //移出门店
    this.$api.post(api, { id: user.id, enableTransfer: false }).then((res) => {
      const [data] = res;
      const exitLead: boolean = data.exitLead;
      console.log(data);
      if (exitLead) {
        this.initStep2();
      } else {
        user.disabled = true;
        user.disabledValue = "已移出门店";
        ElMessage.success("操作成功!");
        this.close();
      }
    });
  }

  initStep2() {
    this.step1Flag = false;
    this.step2Flag = true;
  }

  step2() {
    this.initStep3();
  }

  initStep3() {
    const ruleForm: FormInstance = this.$refs.ruleFormRef as any;
    ruleForm.resetFields();
    this.shopUserArray = this.pocket("shopTransferUser", {
      filter: (item) => item.value !== this.user.id,
    });
    this.step1Flag = false;
    this.step2Flag = false;
    this.step3Flag = true;
  }

  step3() {
    const ruleForm: FormInstance = this.$refs.ruleFormRef as any;
    ruleForm.validate((valid) => {
      if (valid) {
        if ((this?.$stores as any).store.state.CommonStore.terminal === "ops") {
          var api = "/ops/userTenant/remove";
        } else {
          var api = "/mgr/userTenant/remove";
        }
        this.$api
          .post(api, {
            id: this.user.id,
            toTransferUserId: this.disableParam.toTransferUserId,
            enableTransfer: true,
          })
          .then((res) => {
            this.user.disabled = true;
            this.user.disabledValue = "已移出门店";
            ElMessage.success("操作成功!");
            this.close();
          });
      }
    });
  }

  close() {
    this.step2Flag = false;
    this.step3Flag = false;
    this.step1Flag = true;
    this.$emit("close");
  }
}

class MgrUserDisableBO {
  id: string = "";
  toTransferUserId: string = "";
  enableTransfer: boolean = false;
}
