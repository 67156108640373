import {
  RequestMapping,
  RestController,
  PostMapping,
  Pocket,
  ExtraOptions
} from "@/common/decorators";
import { GenerateRecordSearchParamVO, GenerateRecordSearchResultVO, GenerateSubsidiaryParamVO, GenerateResultVO, GenerateRegionParamVO, GenerateRetailBusinessParamVO, RptGenerateRecordStatusEnum } from "../vo";
import { SearchResult } from "@ldos/shared-common/src";

import { v4 as uuidv4 } from 'uuid';
import { generateRandomArray } from "@ldos/shared-common/src/util/mock";
import { CockpitYearVO, CockpitYearSubsidiaryVO } from "@/views/home/vo";
const faker = require('faker');

let yearC = 2021
function generateCockpitYearVO(): CockpitYearVO {
  const year: string = yearC + '';
  yearC++;
  const subsidiaryCount: number = faker.random.number({ min: 1, max: 5 });
  const subsidiaries: CockpitYearSubsidiaryVO[] = [];

  for (let i = 0; i < subsidiaryCount; i++) {
    const subsidiary: CockpitYearSubsidiaryVO = {
      id: uuidv4(),
      name: faker.company.companyName(),
      regionId: Math.random() > 0.5 ? '123' : '234',
    };
    subsidiaries.push(subsidiary);
  }

  return {
    year,
    region: [{ id: '123', name: '区域123', subsidiary: [] }, { id: '234', name: '区域234', subsidiary: [] }],
    subsidiary: subsidiaries
  };
}

function getGenerateRecordSearchResultVO(): GenerateRecordSearchResultVO {
  return {
    id: uuidv4(),
    year: faker.date.past().getFullYear().toString(),
    companyId: uuidv4(),
    companyName: faker.company.companyName(),
    status: faker.random.arrayElement(Object.values(RptGenerateRecordStatusEnum)),
    description: faker.lorem.sentence(),
    createTime: faker.date.recent().getTime()
  };
}

@RestController
@RequestMapping("/mgr/rpt/generateRecord")
export default class RptGenerateRecordController {
  loadFlag = true;
  async search(param: GenerateRecordSearchParamVO): Promise<SearchResult<GenerateRecordSearchResultVO>> {
    const reqFn = this.loadFlag ? this.load : this.page;
    this.loadFlag = false;
    return reqFn(param);
  }

  @Pocket(RptGenerateRecordStatusEnum, 'rptGenerateRecordStatus')
  @PostMapping("/year")
  async year(): Mono<CockpitYearVO[]> {
    return generateRandomArray(generateCockpitYearVO, 2) as CockpitYearVO[];
  }

  @PostMapping("/load")
  async load(param: GenerateRecordSearchParamVO): Promise<SearchResult<GenerateRecordSearchResultVO>> {
    return this.page(param);
  }

  @PostMapping("/page")
  async page(param: GenerateRecordSearchParamVO): Promise<SearchResult<GenerateRecordSearchResultVO>> {
    return {
      totalElements: 13,
      result: generateRandomArray(getGenerateRecordSearchResultVO),
      hasMore: true,
    };
  }

  @ExtraOptions({ timeout: 60000 })
  @PostMapping("/subsidiary")
  async subsidiary(generateSubsidiaryParamVO: GenerateSubsidiaryParamVO): Promise<GenerateResultVO> {
    return {
      success: true,
      descriptionList: ['成功了']
    } as any;
  }

  @ExtraOptions({ timeout: 60000 })
  @PostMapping("/region")
  async region(generateRegionParamVO: GenerateRegionParamVO): Promise<GenerateResultVO> {
    return {
      success: false,
      descriptionList: ['成功了']
    } as any;
  }

  @ExtraOptions({ timeout: 60000 })
  @PostMapping("/retailBusiness")
  async retailBusiness(generateRetailBusinessParamVO: GenerateRetailBusinessParamVO): Promise<GenerateResultVO> {
    return {
      success: false,
      descriptionList: ['成功了', '确实']
    } as any;
  }
}

