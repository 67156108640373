import configuration from "./configuration";
import home from "./home";
import report from './report'
import noRouterView from "./noRouterView";
import rm from "./rm";
import dataProcessing from "./dataProcessing";
export default [
  ...configuration,
  ...home,
  ...report,
  ...noRouterView,
  ...rm,
  ...dataProcessing
]