/**
 *
 * 项目的菜单树
 * 排序：引入项目的前后顺序
 * 区分：【线索通，i营销，通用】
 * 注：每一个模块均都为一级菜单
 */
import environment from "@@/environment";
import { ENVIRONMENT_BUILD_DEPLOYMENT_LOCAL } from '@velites/init';

// 通用
import common from "./common";
import commonMenu from "./common/menu"

import demo from "./demo"  // 没有任何路由权限时

export default [
  // 车听鉴
  // 通用
  ...common,
  ...(!environment.build.deployment || environment.build.deployment === ENVIRONMENT_BUILD_DEPLOYMENT_LOCAL ? demo : []),
];

export const businessRoutes = [
  ...common,
]

export const businessMenu = [
  ...commonMenu,
]