import moment from "moment";
import { ElMessage } from "element-plus";
import stubs from "@/init";
import { SimpleItem } from "@velites/common";

export const conversionTimeFormat = (n: number | string): string => {
  let str = "";
  let time = moment.duration(Number(n), "seconds");
  let hours = time.hours();
  let minutes = time.minutes();
  let seconds = time.seconds();
  str = moment({ h: hours, m: minutes, s: seconds }).format("HH:mm:ss");
  return str;
};

export const initOptions = (filters: any, pockets: any) => {
  // 页面option与pockets进行关联
  if (!pockets) return [];
  let arr: any[] = [];
  filters.forEach((x: any) => {
    if (pockets[x.name]) x.options = pockets[x.name];
    arr.push(x);
  });
  return arr || [];
};

export const setFiltersPocketsText = (filters: any, t: string, s: string) => {
  // 部分table内部字段根据pockets相关配置项进行展示
  let str = "";
  filters.forEach((x: any) => {
    if (x.name === t)
      str = x.options?.filter((x: any) => x.value === s)[0]?.label;
  });
  return str || "--";
};

export const timeFormat = (timestamp: number | string | null | undefined, type: number = 0) => {
  if (!Number(timestamp)) return "-";
  let formartText = type ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm:ss";
  return moment(new Date(Number(timestamp))).format(formartText);
};

export const columnFormat = (row: any, column: any) => {
  const { property } = column;
  return row[property] || "-";
};

export const requestTimeFormat = (str: string) => {
  return str ? new Date(str).getTime() : null;
};

export const consoleFormatObj = (obj: any) => {
  console.log(JSON.stringify(obj, null, "\t"));
};

export const getDateTime = () => {
  return new Date().getTime();
};

/**
 * 功能：之寻找下个数据源 支持分页查找
 * @param id [上一个对象id]
 * @param current [当前页码]
 * @param totalPages [总页码]
 * @param dataList [列表数据源]
 * @param selectNext [直接调起下一条数据的回调]
 * @param seekNext [需要进行分页，然后调起第一条数据的回调]
 */
export interface ISeekNextDataSourceConfig {
  id: string;
  current: number;
  totalPages: number;
  dataSource: any;
  selectNextSource: Function;
  seekNextSource: Function;
}
export const seekNextDataSource = (o: ISeekNextDataSourceConfig) => {
  let curObj = o.dataSource.filter((x: any) => x.id === o.id)[0];
  let curIndex = o.dataSource.indexOf(curObj);

  if (o.current === o.totalPages && curIndex > 8) {
    ElMessage({
      type: "warning",
      message: "当前是最后一页"
    });
    return false;
  }
  if (curIndex + 1 === 10) {
    // 需要进行分页筛选
    o.current = o.current + 1;
    let seekNeedObj = {
      seek: "seekNext",
      cur: o.current
    };
    o.seekNextSource(seekNeedObj);
  } else {
    // 直接进行选择下一个
    if (o.dataSource[curIndex + 1]) {
      o.selectNextSource(o.dataSource[curIndex + 1]);
    } else {
      ElMessage({
        type: "warning",
        message: "数据已经是最后一条"
      });
    }
  }
};

export const integer = (value: string, mode: number) => {
  /* 只能输入正整数 or 保留小数点后2位 or 保留小数点后1位 */
  // mode:0 只能输入整数  1：保留小数点后面1位 2：保留小数点后面2位
  let v;
  switch (mode) {
    case 0:
      v = value.replace(/[^0-9]/g, "");
      break;
    case 1:
      v = value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, "$1");
      break;
    case 2:
      v = value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, "$1");
      break;
    default:
      break;
  }
  return v;
};

export const masDiv = (num1: string | number, num2: string | number) => {
  // 除函数 - hbb
  let t1 = 0;
  let t2 = 0;
  try {
    t1 = num1.toString().split(".")[1].length;
  } catch (e) {
    // Error
  }
  try {
    t2 = num2.toString().split(".")[1].length;
  } catch (e) {
    // Error
  }
  const r1 = Number((num1 + "").replace(".", ""));
  const r2 = Number((num2 + "").replace(".", ""));
  return (r1 / r2) * 10 ** (t2 - t1);
};

export const downloadByteArraystream = (res: any) => {
  let type = "application/octet-stream";
  let blob = new Blob([res.data], { type: type });
  let disposition = res.headers["content-disposition"];
  let fileName = "";
  try {
    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, "");
    }
    fileName = decodeURI(fileName).replace("utf-8", "");
  } catch (e) {
    fileName = `file-${new Date().getTime()}`;
  }
  let URL = window.URL || window.webkitURL;
  let objectUrl = URL.createObjectURL(blob);
  var aLink = document.createElement("a");
  aLink.href = objectUrl;
  aLink.download = fileName;
  document.body.appendChild(aLink);
  aLink.click();
  aLink.remove();
};

//获取本地存储的数据
export function getLocalStorageValue(key: string) {
  const value = localStorage.getItem(key);
  if (!value) return null;
  try {
    return JSON.parse(value);
  } catch (error) {
    return null;
  }
}
//设置本地存储的数据
export function setLocalStorage(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function setEncryptionPhone(v: string) {
  // 处理手机号的加密
  if (!v) return "";
  return v.substr(0, 3) + "********";
}

// 控制最多n个字符
export const contentTextLength = (
  str: string | null | undefined,
  len: number,
  spot: boolean = true
) => {
  if (!str) return "--";
  let text = "";
  let spotText = spot ? "..." : "";
  if (str.length > len) {
    text = str.substring(0, len) + spotText;
  } else {
    text = str;
  }
  return text;
};


export const handleDownloadResponse = (response: any) => {
  if (response && response.status === 200) {
    downloadByteArraystream(response);
    ElMessage({
      type: 'success',
      message: '导出成功'
    });
  } else {
    ElMessage({
      type: 'error',
      message: '导出失败'
    });
  }
}

export const injectSuiteIdAndCorpId = (body: any): any => {
  const suiteId = (stubs as any).wecom.obtainImktSuite()?.suiteId;
  const corpId = (stubs as any).wecom.obtainDefaultImktCorp()?.corpId;
  body.suiteId = suiteId;
  body.corpId = corpId;
  return body;
}

export const obtainOrganizationalStructure = async (): Promise<Array<SimpleItem>> => {
  const [options] = await stubs.api.post('/organizationalStructure/obtainOrganizationalStructure', injectSuiteIdAndCorpId({}));
  return options;
}

export const getDayTimestamps = (time: Date | number | string | undefined, isStartTime = false): number => {
  let date: Date = time as Date;
  !(time instanceof Date) && (date = new Date(time || 0));
  const newDate = new Date(0);
  newDate.setFullYear(date.getFullYear());
  newDate.setMonth(date.getMonth());
  newDate.setDate(date.getDate());
  newDate.setTime(newDate.getTime() - 8 * 3600000);
  return newDate.getTime() + (isStartTime ? 0 : 24 * 3600000 - 1);
}

export const obtainRandomStr = (n = 16) => {
  const $chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  return new Array(n).reduce((prev) => prev + $chars.charAt(Math.floor(Math.random() * $chars.length)), '')
}

export const hashFunc = (str: string, size: number) => {
  if (size <= 0) return 0;
  let hashCode = 0;
  let index = 0;
  for (let i = 0; i < str.length; i++) {
    hashCode = hashCode * 37 + str.charCodeAt(i);
  }
  index = hashCode % size;

  return index;
}

export const gradeToDescription = (num: number, withoutStart = false) => {
  if (!num || typeof num !== 'number') return '-';
  return `${withoutStart ? '' : ''}${num < 0 ? '上升' : '下降'}${Math.abs(num)}级`;
}

export const hexToRgba = (hex: string, alpha: number) => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

// 示例使用
const hexColor = '#FF0000'; // 红色
const alpha = 0.5; // 透明度
const rgbaColor = hexToRgba(hexColor, alpha);
console.log(rgbaColor); // 输出 rgba(255, 0, 0, 0.5)
