const parentMenuKey = "rm";
export default [
    {
        meta: {
          permissionCode: "Mgr.RiskWarn.RiskWarn.View",
          title: "风险预警",
          parentMenuKey
        },
        icon: "",
        path: "/configuration/risk-warn",
        component: () => import("@/views/risk-warn/views/risk-warn/index.vue")
      },
];