const parentMenuKey = "dataProcessing";
export default [
  {
    meta: {
      permissionCode: "Mgr.Rpt.Generate.View",
      title: "生成报表",
      parentMenuKey
    },
    icon: "",
    path: "/report/generate-report",
    component: () => import("@/views/report/views/generate-report/index.vue")
  },
];