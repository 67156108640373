import {
  RequestParam,
  RequestMapping,
  RestController,
  PostMapping,
} from '@/common/decorators';
import { HighRiskKriMetricsModuleVO, KriMetricsVO, RegionDetailVO, RiskModuleSimpleVO, RiskTypeEnum } from '../vo';

import { v4 as uuidv4 } from 'uuid';

const faker = require('faker');

function generateModuleList(): RiskModuleSimpleVO[] {
  const modules: RiskModuleSimpleVO[] = [];
  const moduleNames = [
    '市场秩序',
    '新渠道',
    '合规模块',
    '绩效模块',
    '成本费用',
    '经销商盈利',
  ];

  moduleNames.forEach((moduleName, index) => {
    modules.push({
      id: uuidv4(),
      name: moduleName,
      level: faker.datatype.number({ min: 1, max: 5 }),
      trendLevel: faker.datatype.number({ min: -5, max: 5 }),
    });
  });

  return modules;
}

function generateHighRiskKriMetricsModuleList(): HighRiskKriMetricsModuleVO[] {
  const modules: HighRiskKriMetricsModuleVO[] = [];
  const moduleNames = [
    'High Risk KRI Metrics Module 1',
    'High Risk KRI Metrics Module 2',
  ];

  moduleNames.forEach((moduleName, index) => {
    modules.push({
      name: moduleName,
      kriMetricsList: generateKriMetricsList(),
    });
  });

  return modules;
}

function generateKriMetricsList(): KriMetricsVO[] {
  const kriMetricsList: KriMetricsVO[] = [];
  const kriMetricNames = [
    'KRI Metric 1',
    'KRI Metric 2',
    'KRI Metric 3',
  ];

  kriMetricNames.forEach((kriMetricName, index) => {
    kriMetricsList.push({
      id: uuidv4(),
      name: kriMetricName,
      kriValue: faker.datatype.number({ min: 0, max: 100 }),
      displayKriValue: faker.datatype.number({ min: 0, max: 100 }),
      kriLevel: faker.datatype.number({ min: 1, max: 5 }),
      trendLevel: faker.datatype.number({ min: -5, max: 5 }),
      weight: faker.datatype.number({ min: 0, max: 100 }),
      type: RiskTypeEnum.TYPE_A,
    });
  });

  return kriMetricsList;
}

function getRegionDetailVO(): RegionDetailVO {
  const regionDetailVO: RegionDetailVO = {
    id: uuidv4(),
    name: faker.address.state(),
    year: faker.datatype.number({ min: 2022, max: 2023 }),
    level: faker.datatype.number({ min: 1, max: 5 }),
    trendLevel: faker.datatype.number({ min: -5, max: 5 }),
    scope: ['510000'],
    moduleList: generateModuleList(),
    subsidiaryList: [{
      id: uuidv4(),
      name: faker.address.state(),
      level: faker.datatype.number({ min: 1, max: 5 }),
      trendLevel: faker.datatype.number({ min: -5, max: 5 }),
      highRiskModuleCount: faker.datatype.number({ min: 1, max: 10 }),
      highRiskKriMetricsCount: faker.datatype.number({ min: 1, max: 10 }),
      longitude: '104.065735',
      latitude: '30.659462',
      moduleList: generateModuleList(),
      highRiskKriMetricsModuleList: generateHighRiskKriMetricsModuleList(),
    }],
  };

  return regionDetailVO;
}


@RestController
@RequestMapping('/mgr/cockpit/region')
export default class CockpitRegionController {
  @PostMapping('')
  async region(@RequestParam('regionId') regionId: string, @RequestParam('year') year: string) {
    return getRegionDetailVO();
  }
}
