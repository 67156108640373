import moment from "moment";
import { ElMessage } from "element-plus";
import _ from "lodash";

export const conversionTimeFormat = (n: number | string): string => {
  if (!n) return "";
  let str = "";
  let time = moment.duration(Number(n), "seconds");
  let hours = time.hours();
  let minutes = time.minutes();
  let seconds = time.seconds();
  str = moment({ h: hours, m: minutes, s: seconds }).format("HH:mm:ss");
  return str;
};

export const initOptions = (filters: any, pockets: any) => {
  // 页面option与pockets进行关联
  if (!pockets) return [];
  let arr: any[] = [];
  filters.forEach((x: any) => {
    if (pockets[x.name]) x.options = pockets[x.name];
    arr.push(x);
  });
  return arr || [];
};

export const setFiltersPocketsText = (filters: any, t: string, s: string) => {
  // 部分table内部字段根据pockets相关配置项进行展示
  let str = "";
  filters.forEach((x: any) => {
    if (x.name === t)
      str = x.options?.filter((x: any) => x.value === s)[0]?.label;
  });
  return str || "--";
};

export const timeFormat = (timestamp: number | string, type: number = 0) => {
  if (!Number(timestamp)) return "-";
  let formartText = type ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm:ss";
  return moment(new Date(Number(timestamp))).format(formartText);
};

export const columnFormat = (row: any, column: any) => {
  const { property } = column;
  return row[property] || "-";
};

export const requestTimeFormat = (str: string) => {
  return str ? new Date(str).getTime() : null;
};

export const consoleFormatObj = (obj: any) => {
  console.log(JSON.stringify(obj, null, "\t"));
};

export const getDateTime = () => {
  return new Date().getTime();
};

/**
 * 功能：之寻找下个数据源 支持分页查找
 * @param id [上一个对象id]
 * @param current [当前页码]
 * @param totalPages [总页码]
 * @param dataList [列表数据源]
 * @param selectNext [直接调起下一条数据的回调]
 * @param seekNext [需要进行分页，然后调起第一条数据的回调]
 */
export interface ISeekNextDataSourceConfig {
  id: string;
  current: number;
  totalPages: number;
  dataSource: any;
  selectNextSource: Function;
  seekNextSource: Function;
}
export const seekNextDataSource = (o: ISeekNextDataSourceConfig) => {
  let curObj = o.dataSource.filter((x: any) => x.id === o.id)[0];
  let curIndex = o.dataSource.indexOf(curObj);

  if (o.current === o.totalPages && curIndex > 8) {
    ElMessage({
      type: "warning",
      message: "当前是最后一页"
    });
    return false;
  }
  if (curIndex + 1 === 10) {
    // 需要进行分页筛选
    o.current = o.current + 1;
    let seekNeedObj = {
      seek: "seekNext",
      cur: o.current
    };
    o.seekNextSource(seekNeedObj);
  } else {
    // 直接进行选择下一个
    if (o.dataSource[curIndex + 1]) {
      o.selectNextSource(o.dataSource[curIndex + 1]);
    } else {
      ElMessage({
        type: "warning",
        message: "数据已经是最后一条"
      });
    }
  }
};

export const integer = (value: string, mode: number) => {
  /* 只能输入正整数 or 保留小数点后2位 or 保留小数点后1位 */
  // mode:0 只能输入整数  1：保留小数点后面1位 2：保留小数点后面2位
  let v;
  switch (mode) {
    case 0:
      v = value.replace(/[^0-9]/g, "");
      break;
    case 1:
      v = value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, "$1");
      break;
    case 2:
      v = value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, "$1");
      break;
    default:
      break;
  }
  return v;
};

export const masDiv = (num1: string | number, num2: string | number) => {
  // 除函数 - hbb
  let t1 = 0;
  let t2 = 0;
  try {
    t1 = num1.toString().split(".")[1].length;
  } catch (e) {
    // Error
  }
  try {
    t2 = num2.toString().split(".")[1].length;
  } catch (e) {
    // Error
  }
  const r1 = Number((num1 + "").replace(".", ""));
  const r2 = Number((num2 + "").replace(".", ""));
  return (r1 / r2) * 10 ** (t2 - t1);
};

export const downloadByteArraystream = (res: any) => {
  let type = "application/octet-stream";
  let blob = new Blob([res.data], { type: type });
  let disposition = res.headers["content-disposition"];
  let fileName = "";
  try {
    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, "");
    }
    fileName = decodeURI(fileName).replace("utf-8", "");
  } catch (e) {
    fileName = `file-${new Date().getTime()}`;
  }
  let URL = window.URL || window.webkitURL;
  let objectUrl = URL.createObjectURL(blob);
  var aLink = document.createElement("a");
  aLink.href = objectUrl;
  aLink.download = fileName;
  document.body.appendChild(aLink);
  aLink.click();
  aLink.remove();
};

//获取本地存储的数据
export function getLocalStorageValue(key: string) {
  const value = localStorage.getItem(key);
  if (!value) return null;
  try {
    return JSON.parse(value);
  } catch (error) {
    return null;
  }
}
//设置本地存储的数据
export function setLocalStorage(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function setEncryptionPhone(v: string) {
  // 处理手机号的加密
  if (!v) return "";
  return v.substr(0, 3) + "********";
}
export const titleCase = (str: string) => {
  let newStr = str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
  return newStr;
};

// 控制最多n个字符
export const contentTextLength = (
  str: string | null | undefined,
  len: number,
  spot: boolean = true,
  replaceStr: string = "..."
) => {
  if (!str) return "--";
  let text = "";
  let spotText = spot ? replaceStr : "";
  if (str.length > len) {
    text = str.substring(0, len) + spotText;
  } else {
    text = str;
  }
  return text;
};

export function shallowCopyByKeys(source: any, keys: string[], def?: any): any {
  if (typeof source !== 'object' || source == null) return source;
  
  const copyObj: any = {};
  
  for (const key of keys) {
    copyObj[key] = (source[key] === undefined || source[key] === null) ? def : source[key];
  }
  
  return copyObj;
}

export function copyToCommond(str: string) {
  const input = document.createElement("input");
  document.body.appendChild(input);
  input.setAttribute("value", str);
  input.select();
  document.execCommand("copy");
  document.body.removeChild(input);
}


export function isMobile(): boolean {
  const checkResult = window.navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry)/i
  );
  return checkResult != undefined && (checkResult as Array<any>)?.length > 0;
}


export const singleApply = (function() {
  const map = new Map();
  return function(fn: (...args: any) => Promise<any>, ...args: Array<any>): Promise<any> {
    if (map.has(fn)) {
      return map.get(fn);
    } else {
      const result = new Promise<any>(async (resolve, reject) => {
        const res = await fn.apply(args).catch(err => {
          map.delete(fn);
          reject(err);
        });
        if (res) {
          map.delete(fn);
          resolve(res);
        }
      })
      map.set(fn, result);
      return result;
    }
  }
}())

export function findPath(obj: Object, path: string): any | undefined {
  if (typeof obj !== 'object') throw Error('查询的参数非对象');
  const keys = path.split('.');
  let result: any = obj;

  for (const key of keys) {
    if (result && typeof result === 'object' && key in result) {
      result = result[key];
    } else {
      return undefined;
    }
  }

  return result;
}
