import {
  RequestParam,
  RequestMapping,
  RestController,
  PostMapping,
} from '@/common/decorators';
import { RegionSimpleVO, RetailBusinessVO, RiskModuleSimpleVO } from '../vo';

import { v4 as uuidv4 } from 'uuid';

const faker = require('faker');

function generateModuleList(): RiskModuleSimpleVO[] {
  const modules: RiskModuleSimpleVO[] = [];
  const moduleNames = [
    '市场秩序',
    '新渠道',
    '合规模块',
    '绩效模块',
    '成本费用',
    '经销商盈利',
  ];

  moduleNames.forEach((moduleName, index) => {
    const level = faker.datatype.number({ min: 1, max: 5 });
    modules.push({
      id: uuidv4(),
      name: moduleName,
      level,
      trendLevel: faker.datatype.number({ min: -5, max: 5 }),
    });
  });

  return modules;
}

function generateRegionList(): RegionSimpleVO[] {
  const regions: RegionSimpleVO[] = [
    { id: uuidv4(), name: '四川大区', level: faker.datatype.number({ min: 1, max: 5 }), trendLevel: faker.datatype.number({ min: -5, max: 5 }), highRiskModuleCount: faker.datatype.number({ min: 1, max: 10 }), highRiskSubsidiaryCount: faker.datatype.number({ min: 1, max: 10 }), scope: ['510000', '630000'], longitude: '104.0668', latitude: '30.5728' },
    { id: uuidv4(), name: '山东大区', level: faker.datatype.number({ min: 1, max: 5 }), trendLevel: faker.datatype.number({ min: -5, max: 5 }), highRiskModuleCount: faker.datatype.number({ min: 1, max: 10 }), highRiskSubsidiaryCount: faker.datatype.number({ min: 1, max: 10 }), scope: ['370000'], longitude: '117.1201', latitude: '36.6512' },
    { id: uuidv4(), name: '江苏大区', level: faker.datatype.number({ min: 1, max: 5 }), trendLevel: faker.datatype.number({ min: -5, max: 5 }), highRiskModuleCount: faker.datatype.number({ min: 1, max: 10 }), highRiskSubsidiaryCount: faker.datatype.number({ min: 1, max: 10 }), scope: ['320000'], longitude: '118.7969', latitude: '32.0603' },
    { id: uuidv4(), name: '湖北大区', level: faker.datatype.number({ min: 1, max: 5 }), trendLevel: faker.datatype.number({ min: -5, max: 5 }), highRiskModuleCount: faker.datatype.number({ min: 1, max: 10 }), highRiskSubsidiaryCount: faker.datatype.number({ min: 1, max: 10 }), scope: ['420000'], longitude: '114.3055', latitude: '30.5928' },
  ];

  return regions;
}

function getRetailBusinessVO(): RetailBusinessVO {
  return {
    year: faker.datatype.number({ min: 2022, max: 2023 }),
    name: faker.company.companyName(),
    level: faker.datatype.number({ min: 1, max: 5 }),
    trendLevel: faker.datatype.number({ min: -5, max: 5 }),
    moduleList: generateModuleList(),
    regionList: generateRegionList(),
  };
}

@RestController
@RequestMapping('/mgr/cockpit/retailBusiness')
export default class CockpitRetailBusinessController {
  @PostMapping('')
  async retailBusiness(@RequestParam('year') year: string): Mono<RetailBusinessVO> {
    return getRetailBusinessVO();
  }
}
