import {
  RequestParam,
  RequestMapping,
  RestController,
  PostMapping,
} from '@/common/decorators';
import { HighRiskKriMetricsModuleVO, KriMetricsVO, KriRiskModuleVO, ReverseEventModuleVO, ReverseEventVO, RiskModuleKriVO, RiskTypeEnum, SubsidiaryVO } from '../vo';

import { v4 as uuidv4 } from 'uuid';
import { generateRandomArray } from '@ldos/shared-common/src/util/mock';
const faker = require('faker');
function generateModuleList(): KriRiskModuleVO[] {
  const modules: KriRiskModuleVO[] = [];
  const moduleNames = [
    'Module 1',
    'Module 2',
    'Module 3',
  ];

  moduleNames.forEach((moduleName, index) => {
    modules.push({
      id: uuidv4(),
      name: moduleName,
      level: faker.datatype.number({ min: 1, max: 5 }),
      trendLevel: faker.datatype.number({ min: -5, max: 5 }),
      riskModuleKriList: generateRiskModuleKriList(),
    });
  });

  return modules;
}

function generateRiskModuleKriList(): RiskModuleKriVO[] {
  const kriList: RiskModuleKriVO[] = [];
  const kriNames = [
    'KRI 1',
    'KRI 2',
    'KRI 3',
  ];

  kriNames.forEach((kriName, index) => {
    kriList.push({
      id: uuidv4(),
      name: kriName,
      type: RiskTypeEnum.TYPE_A,
      value: faker.datatype.number({ min: 0, max: 100 }),
      weight: faker.datatype.number({ min: 0, max: 100 }),
      kriMetricsList: generateKriMetricsList(),
    });
  });

  return kriList;
}

function generateKriMetricsList(): KriMetricsVO[] {
  const kriMetricsList: KriMetricsVO[] = [];
  const kriMetricNames = [
    'KRI Metric 1',
    'KRI Metric 2',
    'KRI Metric 3',
  ];

  kriMetricNames.forEach((kriMetricName, index) => {
    kriMetricsList.push({
      id: uuidv4(),
      name: kriMetricName,
      kriValue: faker.datatype.number({ min: 0, max: 100 }),
      kriLevel: faker.datatype.number({ min: 1, max: 5 }),
      displayKriValue: faker.datatype.number({ min: 0, max: 100 }),
      trendLevel: faker.datatype.number({ min: -5, max: 5 }),
      weight: faker.datatype.number({ min: 0, max: 100 }),
      type: RiskTypeEnum.TYPE_A,
    });
  });

  return kriMetricsList;
}

function generateKriMetricsVO(): KriMetricsVO {
  return {
    id: uuidv4(),
    name: faker.lorem.word(),
    kriValue: faker.random.number({ min: 0, max: 100 }),
    kriLevel: faker.random.number({ min: 1, max: 5 }),
    trendLevel: faker.random.number({ min: 0, max: 5 }),
    displayKriValue: faker.datatype.number({ min: 0, max: 100 }),
    weight: faker.random.number({ min: 0, max: 100 }),
    type: RiskTypeEnum.TYPE_A, // 请替换为你的枚举值
  };
}

// 生成随机的 HighRiskKriMetricsModuleVO 对象
export function getHighRiskKriMetricsModuleVO(): HighRiskKriMetricsModuleVO {
  const name = faker.lorem.word();
  const kriMetricsList: KriMetricsVO[] = [];
  for (let i = 0; i < 3; i++) {
    kriMetricsList.push(generateKriMetricsVO());
  }
  return { name, kriMetricsList };
}

function generateReverseEventVO(): ReverseEventVO {
  return {
    id: uuidv4(),
    name: faker.lorem.words(),
  };
}

// 生成随机的 ReverseEventModuleVO 对象
export function getReverseEventModuleVO(): ReverseEventModuleVO {
  const name = faker.lorem.word();
  const reverseEventList: ReverseEventVO[] = [];
  for (let i = 0; i < 5; i++) {
    reverseEventList.push(generateReverseEventVO());
  }
  return { name, reverseEventList };
}


function getSubsidiaryVO(): SubsidiaryVO {
  const subsidiaryVO: SubsidiaryVO = {
    id: uuidv4(),
    regionId: '',
    name: faker.company.companyName(),
    year: faker.datatype.number({ min: 2022, max: 2023 }),
    level: faker.datatype.number({ min: 1, max: 5 }),
    trendLevel: faker.datatype.number({ min: -5, max: 5 }),
    highRiskModuleCount: faker.datatype.number({ min: 1, max: 10 }),
    highRiskKriMetricsCount: faker.datatype.number({ min: 1, max: 10 }),
    moduleList: generateModuleList(),
    highRiskKriMetricsModuleList: generateRandomArray(getHighRiskKriMetricsModuleVO, 3),
    reverseEventModuleList: generateRandomArray(getReverseEventModuleVO, 3) as Array<ReverseEventModuleVO>,
  };

  return subsidiaryVO;
}

@RestController
@RequestMapping('/mgr/cockpit/subsidiary')
export default class CockpitSubsidiaryController {
  @PostMapping('')
  async region(@RequestParam('subsidiaryId') subsidiaryId: string, @RequestParam('year') year: string) {
    return getSubsidiaryVO() as SubsidiaryVO;
  }
}
