import { Nullable } from "@velites/common";
import moment from "moment";

export function formatPercentage(v: any) {
  if (typeof v === 'number') {
    return `${v * 100}%`
  }
  return v;
}

export function defaultDisplayIfEmpty(v: any, defaultDisplay?: any) {
  if (v == null || v === '') {
    return defaultDisplay;
  }
  return v;
}

export class DateTimeFormatter {
  private dateTimeFormat: string;

  constructor(dateTimeFormat: string) {
    this.dateTimeFormat = dateTimeFormat;
  }

  format(originDate: Nullable<number | Date | string>, defaultValue = "-"): string {
    const date = originDate instanceof Date ? originDate : new Date(originDate as any);
    if (originDate == null || Number.isNaN(date.getTime())) {
      return defaultValue;
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hour = date.getHours().toString().padStart(2, "0");
    const minute = date.getMinutes().toString().padStart(2, "0");
    const second = date.getSeconds().toString().padStart(2, "0");

    let result = this.dateTimeFormat;
    result = result.replace("yyyy", year.toString());
    result = result.replace("mm", month);
    result = result.replace("dd", day);
    result = result.replace("HH", hour);
    result = result.replace("MM", minute);
    result = result.replace("SS", second);

    return result;
  }
}


export const timeFormat = (function () {
  let dateTimeFormatInstance: Nullable<DateTimeFormatter> = null;
  return function (time: Nullable<number | Date | string>, defaultValue = "-"): string {
    if (!dateTimeFormatInstance) {
      dateTimeFormatInstance = new DateTimeFormatter("yyyy-mm-dd HH:MM:SS");
    }
    return dateTimeFormatInstance.format(time, defaultValue);
  }
})()

export const dateFormat = (function () {
  let dateTimeFormatInstance: Nullable<DateTimeFormatter> = null;
  return function (time: Nullable<number | Date | string>, defaultValue = "-"): string {
    if (!dateTimeFormatInstance) {
      dateTimeFormatInstance = new DateTimeFormatter("yyyy-mm-dd");
    }
    return dateTimeFormatInstance.format(time, defaultValue);
  }
})()

export const durationFormat = (end: Nullable<Date | number | string>, start: Nullable<Date | number | string>,
  { type = 'minute', fixed = 2 }: { type: 'second' | 'minute' | 'hour' | 'day', fixed: number } = { type: 'minute', fixed: 2 }): string => {
  const startDate = start instanceof Date ? start : new Date(start as any);
  const endDate = end instanceof Date ? end : new Date(end as any);
  if (end == null || Number.isNaN(endDate.getTime())) {
    return "-";
  }
  const duration = endDate.getTime() - (Number.isNaN(startDate.getTime()) ? 0 : startDate.getTime());
  let time = duration;
  switch (type) {
    case 'day':
      time /= 60;
    case 'hour':
      time /= 60;
    case 'minute':
      time /= 60;
    case 'second':
      time /= 1000;
  }
  return time.toFixed(fixed);
};

export const commonDurationFormat = (end: Nullable<Date | number | string>, start: Nullable<Date | number | string>,
  { type = 'minute' }: { type: 'second' | 'minute' | 'hour' | 'day' } = { type: 'minute' }): string => {
  if (!end || !start) return '-';
  const startDate = start instanceof Date ? start : new Date(start as any);
  const endDate = end instanceof Date ? end : new Date(end as any);
  if (end == null || Number.isNaN(endDate.getTime()) || start == null || Number.isNaN(startDate.getTime())) {
    return "-";
  }
  const duration = endDate.getTime() - (Number.isNaN(startDate.getTime()) ? 0 : startDate.getTime());
  if (duration <= 0) {
    return "-";
  }
  let time = duration;
  let day = 0;
  let hour = 0;
  let minute = 0;
  let second = 0;

  const joinFun = (count: number, surfix: string) => {
    return count === 0 ? '' : `${count}${surfix}`
  }

  time = Math.floor(time / 1000);
  second = time % 60;
  time = Math.floor(time / 60);
  minute = time % 60;
  time = Math.floor(time / 60);
  hour = time % 24;
  time = Math.floor(time / 24);
  day = time;

  const result = [];

  switch (type) {
    case 'second':
      result.unshift({ label: '秒', value: second });
    case 'minute':
      result.unshift({ label: '分', value: minute });
    case 'hour':
      result.unshift({ label: '时', value: hour });
    case 'day':
      result.unshift({ label: '天', value: day });
  }
  return result.map((item: any) => joinFun(item.value, item.label)).join('');
};

export function trimStr(str?: string) {
  return (str || '').trim();
};

export function durationBetween(timestamp1: number, timestamp2: number): string {
  if (!(timestamp1 && timestamp2)) return '-';
  const duration = moment.duration(Math.abs(timestamp2 - timestamp1));
  const hours = Math.floor(duration.asHours()).toString().padStart(2, '0');
  const minutes = (Math.floor(duration.asMinutes()) % 60).toString().padStart(2, '0');
  const seconds = (Math.floor(duration.asSeconds()) % 60).toString().padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`
};