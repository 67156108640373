const parentTitle = "演示/测试"
export default [
    {
        path: "/testDemo",
        component: () => import("@/views/demo/index.vue"),
        meta: {
            title: parentTitle,
            menuGroup: true,
            showChildren: true
        },
        children: [
            {
                path: "/testDemo/test1",
                meta: {
                    title: "demo-test1",
                    parentTitle
                },
                component: () => import("@/views/demo/test1.vue")
            },
            {
                path: "/testDemo/createTask",
                meta: {
                    title: "demo测试-创建任务",
                    parentTitle
                },
                component: () => import("@/views/demo/flexy-form/create.vue")
            },
            {
                path: "/testDemo/executeTask",
                meta: {
                    title: "demo测试-执行任务",
                    parentTitle
                },
                component: () => import("@/views/demo/flexy-form/execute.vue")
            }
        ]
    }
]