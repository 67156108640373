import { Pocket, PostMapping, RequestMapping, RequestParam, RestController } from "@/common/decorators";
import { SearchResult } from "@ldos/shared-common/src";
import { Region, RegionSearchVO, RegionStatusEnum } from "../vo";

const faker = require('faker');
import { v4 as uuidv4 } from 'uuid';
import { generateRandomArray } from "@ldos/shared-common/src/util/mock";
import moment from "moment";

const administrativeCodes = {
  '110000': '北京市',
  '120000': '天津市',
  '130000': '河北省',
  '140000': '山西省',
  '150000': '内蒙古自治区',
  '210000': '辽宁省',
  '220000': '吉林省',
  '230000': '黑龙江省',
  '310000': '上海市',
  '320000': '江苏省',
  '330000': '浙江省',
  '340000': '安徽省',
  '350000': '福建省',
  '360000': '江西省',
  '370000': '山东省',
  '410000': '河南省',
  '420000': '湖北省',
  '430000': '湖南省',
  '440000': '广东省',
  '450000': '广西壮族自治区',
  '460000': '海南省',
  '500000': '重庆市',
  '510000': '四川省',
  '520000': '贵州省',
  '530000': '云南省',
  '540000': '西藏自治区',
  '610000': '陕西省',
  '620000': '甘肃省',
  '630000': '青海省',
  '640000': '宁夏回族自治区',
  '650000': '新疆维吾尔自治区',
  '710000': '台湾省',
  '810000': '香港特别行政区',
  '820000': '澳门特别行政区'
}

export const getRegion = (): Region => {
  const id = uuidv4();
  const name = faker.address.city();
  const disabled = faker.random.arrayElement(Object.values(RegionStatusEnum));
  const scope = [
    faker.random.arrayElement(Object.keys(administrativeCodes)),
    faker.random.arrayElement(Object.keys(administrativeCodes)),
    faker.random.arrayElement(Object.keys(administrativeCodes)),
  ];

  return {
    id,
    name,
    disabled,
    scope,
    createTime: moment().valueOf(),
    updateTime: moment().valueOf(),
  };
};


@RestController
@RequestMapping('/sys-region')
export default class RegionController {

  loadFlag = true;
  async search(req: RegionSearchVO): Promise<SearchResult<Region>> {
    const reqFn = this.loadFlag ? this.load : this.page;
    this.loadFlag = false;
    return reqFn(req);
  }

  @Pocket(administrativeCodes, 'province')
  @PostMapping('/load')
  async load(req: RegionSearchVO): Promise<SearchResult<Region>> {
    return this.page(req);
  }

  @PostMapping('/page')
  async page(req: RegionSearchVO): Promise<SearchResult<Region>> {
    return {
      totalElements: 23,
      hasMore: true,
      result: generateRandomArray(getRegion),
    };
  }

  async upset(reqBody: Region): Promise<Boolean> {
    const reqFn = reqBody.id ? this.update : this.create;
    return reqFn(reqBody);
  }

  @PostMapping('/add')
  async create(reqBody: Region): Promise<Boolean> {
    return true;
  }

  @PostMapping('/edit')
  async update(reqBody: Region): Promise<Boolean> {
    return true;
  }

}