import { injectableEx } from "@velites/common";
import { TaskDefinition, SERVICE_IDENTIFIER_FLEX_FORM_ITEM } from "../../def";
import ComponentCreation from "./creation.vue";
import ComponentExecution from "./execution.vue";

@injectableEx({serviceIdentifier: SERVICE_IDENTIFIER_FLEX_FORM_ITEM})
export class TaskSceneType1Definition implements TaskDefinition {
  get sceneType(): string {
    return "scene_type1";
  }
  get creationComponent(): any {
    return ComponentCreation;
  }
  get executionComponent(): any {
    return ComponentExecution;
  }
}
