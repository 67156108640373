import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "mas-dialog-box" }
const _hoisted_2 = {
  key: 0,
  class: "footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, _mergeProps({
      modelValue: _ctx.visibleRef,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visibleRef) = $event)),
      fullscreen: _ctx.fullscreen,
      center: _ctx.center
    }, _ctx.$attrs, {
      title: _ctx.title,
      "append-to-body": _ctx.appendToBody,
      "custom-class": `${_ctx.customClass} ${_ctx.hideHeader ? 'mas-dialog-no-header' : ''}`,
      "show-close": _ctx.closeIcon,
      "close-on-click-modal": _ctx.closeOnClickModal,
      width: _ctx.width,
      modal: _ctx.modal,
      onClose: _ctx.dialogClose,
      draggable: _ctx.draggable,
      "destroy-on-close": _ctx.destroyOnClose,
      "close-on-press-escape": _ctx.closeOnPressEscape
    }), {
      footer: _withCtx(() => [
        (!_ctx.customFooterBtns && _ctx.footer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.$attrs.cancelBtnText)
                ? (_openBlock(), _createBlock(_component_el_button, {
                    key: 0,
                    size: "medium",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visibleRef = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.cancelBtnText), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_el_button, {
                size: "medium",
                type: "primary",
                disabled: _ctx.saveDisabled,
                loading: _ctx.saveBtnLoading,
                onClick: _ctx.dialogSaveClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.saveBtnText), 1)
                ]),
                _: 1
              }, 8, ["disabled", "loading", "onClick"])
            ]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "element-plus-dialog-footer")
      ]),
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "content"),
        (_ctx.customFooterBtns && _ctx.footer)
          ? _renderSlot(_ctx.$slots, "footer", { key: 0 })
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 16, ["modelValue", "fullscreen", "center", "title", "append-to-body", "custom-class", "show-close", "close-on-click-modal", "width", "modal", "onClose", "draggable", "destroy-on-close", "close-on-press-escape"])
  ]))
}