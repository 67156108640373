import { Pocket, PostMapping, RequestMapping, RequestParam, RestController } from "@/common/decorators";
import { SearchResult } from "@ldos/shared-common/src";
import { User,UserSearchVO } from "../vo";

const faker = require('faker');
import { v4 as uuidv4 } from 'uuid';
import { generateRandomArray } from "@ldos/shared-common/src/util/mock";
import moment from "moment";





@RestController
@RequestMapping('/sys-users')
export default class RegionController {

  loadFlag = true;
  async search(req: UserSearchVO): Promise<SearchResult<User>> {
    const reqFn = this.loadFlag ? this.load : this.page;
    this.loadFlag = false;
    return reqFn(req);
  }

  @PostMapping('/load')
  async load(req: UserSearchVO): Promise<SearchResult<User>> {
    return this.page(req);
  }

  @PostMapping('/page')
  async page(req: UserSearchVO): Promise<SearchResult<User>> {
    return {
      totalElements: 23,
      hasMore: true,
      result:[]
    };
  }

  async upset(reqBody: User): Promise<Boolean> {
    const reqFn = reqBody.id ? this.update : this.create;
    return reqFn(reqBody);
  }

  @PostMapping('/add')
  async create(reqBody: User): Promise<Boolean> {
    return true;
  }

  @PostMapping('/edit')
  async update(reqBody: User): Promise<Boolean> {
    return true;
  }

  @PostMapping('/resetPassword')
  async resetPassword(reqBody: User): Promise<Boolean> {
    return true;
  }

}