/**
 *
 * 全局控制
 *
 * **/
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
export interface IRegionData {
  allRegionData: Array<any>;
  province: Array<any>;
  city: Array<any>;
  district: Array<any>;
}
@Module({ name: "CommonStore", namespaced: true })
export default class CommonStore extends VuexModule {

  callState = 0; // 外呼通话状态
  dynamicMapInfo = {}; // 动态字段的数据
  dynamicMapDefaultData = {}; // 动态字段的默认填充数据
  needChangePassword = JSON.parse(localStorage.getItem('needChangePassword') || '{}').needChangePassword; //需要强制修改密码
  firstRouter = {
    path: ""
  }; // 获取项目的第一个router
  terminal = "mgr"

  get getCallState() {
    // 及时获取通话状态
    return this.callState;
  }

  get getDynamicMapInfo() {
    return this.dynamicMapInfo;
  }

  get getDynamicMapDefaultData() {
    return this.dynamicMapDefaultData;
  }

  get getFirstRouter() {
    return this.firstRouter;
  }

  get getNeedChangePassword() {
    return this.needChangePassword;
  }

  @Mutation
  public SET_CALLSTATE(callState: any) {
    // 设置通话状态
    this.callState = callState;
  }

  @Mutation
  public SET_DYNAMIC_MAP(data: any) {
    this.dynamicMapInfo = data;
  }

  @Mutation
  public SET_DYNAMIC_MAP_DEFAULT_DATA(data: any) {
    this.dynamicMapDefaultData = data;
  }

  @Mutation
  public SET_FIRST_ROUTER(data: any) {
    this.firstRouter = data;
  }

  @Mutation
  public SET_NEED_CHANGE_PASSWORD(data: any) {
    localStorage.setItem('needChangePassword', JSON.stringify({ needChangePassword: data }));
    this.needChangePassword = data;
  }
}
