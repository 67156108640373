import { getCurrentInstance, ref } from "vue";
import { DEFAULT_BEHAVIOR } from "../constants/tracking";
export default () => {
  const { proxy } = getCurrentInstance() as any;
  const trackEvent = (props: { trackIngCategory: any; trackingDesc: any }) => {
    proxy.$tracker.track(props.trackIngCategory, {
      behavior: DEFAULT_BEHAVIOR,
      detail: {
        trackingDesc: props.trackingDesc,
        trackingRouterPath: proxy.$route.path
      }
    });
  };
  return {
    trackEvent
  };
};
