
import { CorpVO, SuiteVO } from "@/constant";
import { WECOM_SUITE_KEY_IMKT } from "@ldos/shared-common";
import { InitPluginOptions, InitPluginType } from '@velites/init';
import { ContainerModule, injectable, interfaces, inject } from 'inversify';
import { AccountConsumer } from "@velites/states";
import lodash from "lodash";

@injectable()
export class WecomConsumer {
  constructor(
     @inject(AccountConsumer) private account: AccountConsumer
   ) {
  }
   
  obtainImktSuite(): SuiteVO | undefined {
    return this.account.retrieveExtension<SuiteVO>(WECOM_SUITE_KEY_IMKT)
  }

  obtainDefaultImktCorp(): CorpVO | undefined {
    return lodash(this.obtainImktSuite()?.corps).values().first();
  }
}
 
export const wecomRegistryModule = new ContainerModule(
  (bind: interfaces.Bind, unbind: interfaces.Unbind, isBound: interfaces.IsBound, rebind: interfaces.Rebind) => {
    bind<WecomConsumer>(WecomConsumer).toSelf().inSingletonScope();
  }
)

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $wecom: WecomConsumer;
  }
}

declare module '@velites/init' {
  export interface InitPluginType {
    wecom: WecomConsumer;
  }
}

export function wecomInitialVuePluginOptionsDecorators(opt: InitPluginOptions): void {
  if (!opt.moreInjectionModules) {
    opt.moreInjectionModules = []
  }
  opt.moreInjectionModules.push(wecomRegistryModule);
  if (!opt.moreExtensions) {
    opt.moreExtensions = []
  }
  opt.moreExtensions.push((container, opt, ext) => {
    return { wecom: container.get(WecomConsumer) };
  });
}
