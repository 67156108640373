
/**
 * @Description: el-dialog 二次封装
 */
import { defineComponent, watchEffect, ref } from "vue";
export default defineComponent({
  name: "MasModal",
  props: {
    // 是否插入到body
    appendToBody: {
      type: Boolean,
      default: true,
    },
    // 标题是否居中
    center: {
      type: Boolean,
      default: true,
    },
    // 是否显示弹框
    visible: {
      type: Boolean,
      default: false,
    },
    // 是否需要蒙层
    modal: {
      type: Boolean,
      default: true,
    },
    // 是否展示默认header
    hideHeader: {
      type: Boolean,
      default: false,
    },
    // 自定义class
    customClass: {
      type: String,
      default: "",
    },
    // 是否全屏展示
    fullscreen:{
      type: Boolean,
      default: false
    },
    // 是否支持拖拽
    draggable:{
      type: Boolean,
      default: false,
    },
    // 标题
    title: {
      type: String,
      default: "提示",
    },
    // 是否展示icon
    closeIcon: {
      type: Boolean,
      default: false,
    },
    // 弹框宽度
    width: {
      type: [Number, String],
      default: 500,
    },
    // 点击遮罩隐藏
    closeOnClickModal: {
      type: Boolean,
      default: true,
    },
    // 自定义底部按钮
    customFooterBtns: {
      type: Boolean,
      default: false,
    },
    // 自定义底部按钮
    footer: {
      type: Boolean,
      default: true,
    },
    // 按钮点击事件回调
    onSave: {
      type: Function,
      default: null,
    },
    // 按钮点击事件回调
    onClose: {
      type: Function,
      default: null,
    },
    // 是否可点击
    saveDisabled: {
      type: Boolean,
      default: false,
    },
    // 弹层关闭是否销毁弹层内的内容
    destroyOnClose: {
      type: Boolean,
      default: true
    },
    // 确认按钮文字
    saveBtnText: {
      type: String,
      default: "确 认",
    },
    // 关闭按钮文字
    cancelBtnText: {
      type: String,
      default: "取 消",
    },
    closeOnPressEscape: {
      type: Boolean,
      default: true,
    }
  },
  emits: ["update:visible", "confirm"],
  setup(props, { emit }) {
    const saveBtnLoading = ref(false);
    const visibleRef = ref(false);
    const dialogSaveClick = () => {
      if (props.onSave) {
        props.onSave((loading = false) => {
          saveBtnLoading.value = loading;
        });
      }
      emit("confirm");
    };
    const dialogClose = () => {
      // 关闭弹框回调（嵌套有用）
      if (props.onClose) {
        props.onClose();
      } else {
        saveBtnLoading.value = false;
        emit("update:visible", false);
      }
    };
    watchEffect(() => {
      visibleRef.value = props.visible;
    });
    return {
      props,
      saveBtnLoading,
      visibleRef,
      dialogSaveClick,
      dialogClose,
    };
  },
});
