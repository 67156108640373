
import { Options, Vue } from "vue-class-component";
import { verification } from "@/utils/verification";
import { conversionTimeFormat, timeFormat } from "@/utils/utilsHandle";
import { DateTimeFormatter } from "@@/../shared-common/src/util/formatUtils";

const dateTimeFormatter = new DateTimeFormatter("yyyy-mm-dd HH:MM:SS");
@Options({
  components: {},
})
export default class extends Vue {
  recordingTranslationStatus = false; // 录音转译弹层 是否启动
  sureTranslation: any = {};
  transferredList: any[] = []; // 录音转译的数据源
  keywords: Array<string> = []; // 关键字的数据源
  callLogInfo: any = {}; // 录音信息的数据源
  modalData: any = {
    callLogId: "", // 当前通话id
    isTranslation: "", // 音频当前转义状态
  };
  callInfo = {
    name: "",
    phone: "",
    callType: "",
    callTime: "",
    callDuration: "",
    duration: 0
  };
  
  user = '0';
  customer = '1';

  mounted() {
    this.initLodash();
  }

  initLodash() {
    this.sureTranslation = this.$_.debounce(this.sureTranslationClick, 500);
  }

  active(res: any) {
    // 激活组件
    this.modalData.isTranslation = res.isTranslation;
    this.recordingTranslationStatus = true;
    this.$nextTick(() => {
      this.modalData.callLogId = res.id;
      this.retrieveTranslation(res);
    });
  }

  formatTime(timeNum: number | string): string {
    return conversionTimeFormat(timeNum);
  }

  async retrieveTranslation(res: any) {
    const [result] = await this.$api.get("/mgr/call/retrieveTranslationVo", {
      callLogId: res.id,
    });
    if(result.direction === 'out') {
      this.user = '1'
      this.customer = '0'
    }
    this.callInfo.name = result.customerName;
    this.callInfo.phone = result.customerPhone;
    this.callInfo.callTime = dateTimeFormatter.format(new Date(result.startTime));
    this.callInfo.callType = result.callType;
    this.callInfo.callDuration = conversionTimeFormat(result.callDuration);
    this.callInfo.duration = result.callDuration;
    const callLogBO = result;
    this.callLogInfo = callLogBO || {};
  }

  async sureTranslationClick() {
    await this.$api.get("/call/JustQueueTranslation", {
      callLogId: this.modalData.callLogId,
    });
    this.$Message({
      type: "success",
      message: "已插队，正在转译中...",
    });
  }
  setTimeFormat(num: number) {
    return timeFormat(num);
  }

  detectionKeyword(content: any) {
    // 1.传入关键词数组keyWordArr
    return verification.detectionKeyword(content, this.keywords, "red");
  }
}
