import { Container } from "inversify";
import lodash from "lodash";

export const SERVICE_IDENTIFIER_FLEX_FORM_ITEM = Symbol("SERVICE_IDENTIFIER_FLEX_FORM_ITEM");

export interface TaskDefinition {
  get sceneType(): string;
  get creationComponent(): any;
  get executionComponent(): any;
}

export function obtainRegisteredTaskDefinitions(container: Container) {
  return lodash.keyBy(container.getAll<TaskDefinition>(SERVICE_IDENTIFIER_FLEX_FORM_ITEM), v => v.sceneType);
}