import { Plugin } from "vue";
import { utils } from "@velites/vue-ext";

const componentsRequireContext = require.context(
  "@/components/common/high",
  true,
  /\.(vue|tsx)$/
);


export const allWidgetVueInitPlugin: Plugin = (app) => {
  utils.processRequireContextToComponents(app, [
    componentsRequireContext,
  ]);
};
