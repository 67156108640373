
import {
  defineComponent,
  onMounted,
  SetupContext,
  ref,
  watch,
  getCurrentInstance,
  computed,
} from "vue";

// 默认的日期控件参数z
const DEFAULT_CONFIG = {
  mode: 0, // 控件模式 详见下方：init()
  name: "",
  dateWidth: "",
  specifyDate: "",
  maxSpecifyDate: "",
  needShortcuts: false,
  datePickerType: "daterange",
  specifyDefaultTime: [],
  lastType: "day",
  lastNDate: 7,
  initChangeEmit: true,
};
export default defineComponent({
  name: "MasDatePicker",
  props: {
    mode: {
      type: [String, Number],
      default: DEFAULT_CONFIG.mode,
      required: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: DEFAULT_CONFIG.name,
      required: false,
    },
    dateWidth: {
      type: [String, Number],
      default: DEFAULT_CONFIG.dateWidth,
      required: false,
    },
    specifyDate: {
      type: [Date, String],
      default: DEFAULT_CONFIG.specifyDate,
      required: false,
    },
    maxSpecifyDate: {
      type: [Date, String],
      default: DEFAULT_CONFIG.maxSpecifyDate,
      required: false,
    },
    needShortcuts: {
      type: Boolean,
      default: DEFAULT_CONFIG.needShortcuts,
      required: false,
    },
    datePickerType: {
      type: String,
      default: DEFAULT_CONFIG.datePickerType,
      required: false,
    },
    specifyDefaultTime: {
      type: Array,
      default: DEFAULT_CONFIG.specifyDefaultTime,
      required: false,
    },
    defaultValue: {
      type: Number,
      required: false,
    },
    lastType: {
      type: String,
      default: DEFAULT_CONFIG.lastType,
      required: false,
    },
    dateFormat: {
      type: String,
      default: "YYYY-MM-DD",
    },
    lastNDate: {
      type: Number,
      default: DEFAULT_CONFIG.lastNDate,
      required: false,
    },
    initChangeEmit: {
      type: Boolean,
      default: DEFAULT_CONFIG.initChangeEmit,
      required: false,
    },
    // 通过更新updateKey强制按当前defaultValue重新渲染
    updateKey: {
      type: Number,
    },
    isReset: {
      type: Boolean,
      default: false,
    },
    // 往前和往后推移几天 往前推 正数 往后推  负数即可
    pushForwardNDay: {
      type: Number,
      default: 0
    }
  },
  setup(props, ctx: SetupContext) {
    const instance: any = getCurrentInstance()?.proxy;
    const {
      mode,
      specifyDefaultTime,
      defaultValue,
      lastNDate,
      lastType,
      specifyDate,
      maxSpecifyDate,
      datePickerType,
      name,
      dateFormat,
      initChangeEmit,
      updateKey,
      isReset,
      pushForwardNDay,
    } = props;
    let selectDate: any = ref([]); // 选中的值
    const forceUpdateKey = ref(updateKey);

    const defaultTime = computed(() => {
      const timeRangeDefault = specifyDefaultTime.length
        ? specifyDefaultTime
        : [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)];
      const timePickerDefault = new Date();
      return datePickerType === "daterange" ? timeRangeDefault : timePickerDefault;
    });

    const shortcuts: any[] = [
      {
        text: "上个星期",
        value: (): Array<Date> => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        },
      },
      {
        text: "上个月",
        value: (): Array<Date> => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end];
        },
      },
      {
        text: "近3个月",
        value: (): Array<Date> => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          return [start, end];
        },
      },
    ]; // 快捷选择日期
    const pickerOptions = (time: any): boolean => {
      // Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
      // 禁止指定日期之前的时间选项 [支持最小日期和最大日期的时间范围]
      let maxDate = new Date(maxSpecifyDate).getTime();
      let minDate = new Date(specifyDate).getTime() - 8.64e7
      return time.getTime() < minDate || time.getTime() > maxDate
    };

    onMounted(() => {
      init();
    });

    const init = () => {
      if (datePickerType === "daterange" || datePickerType === "datetimerange") {
        const oldSelectData = [...selectDate.value];
        let now = new Date(); 
        let pushForwardNDays = new Date(now.setDate(now.getDate() - pushForwardNDay));
        switch (mode) {
          case 1:
            // 当前月份 第一天 - 当前天
            selectDate.value = [
              instance.$moment(new Date().setDate(1)).format(dateFormat),
              instance.$moment(pushForwardNDays).format(dateFormat),
            ];
            break;
          case 2:
            // 当前月份 第一天 - 最后一天
            selectDate.value = [
              instance.$moment(new Date().setDate(1)).format(dateFormat),
              instance
                .$moment(new Date().setDate(getCurrentMonthDays()))
                .format(dateFormat),
            ];
            break;
          case 3:
            // 近n year | month | day
            selectDate.value = [
              instance.$moment().subtract(lastNDate, lastType).format(dateFormat),
              instance.$moment(pushForwardNDays).format(dateFormat),
            ];
            break;
          default:
            selectDate.value = [];
            break;
        }
        // 初始化 默认值

        let startTime = selectDate.value[0];
        let endTime = selectDate.value[1];
        let [oldStartTime, oldEndTime] = oldSelectData;
        if (oldStartTime !== startTime || oldEndTime !== endTime) {
          if (!props.initChangeEmit) return false;
          ctx.emit("change", { startTime, endTime, name });
        }
      } else {
        // time picker default value
        selectDate.value = defaultValue;
      }
    };

    const getCurrentMonthDays = (): number => {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const d = new Date(year, month, 0);
      const a = d.getDate();
      return a;
    };

    const dateChange = (): void => {
      let formatStr =
        datePickerType === "daterange" ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm:ss";
      let dateObj = selectDate.value;
      let startTime: string, endTime: string, labelType: string;
      if (datePickerType === "daterange" || datePickerType === "datetimerange") {
        if (dateObj) {
          dateObj = dateObj.map((x: any) => {
            return instance.$moment(x).format(formatStr) || "";
          });
          startTime = dateObj[0];
          endTime = dateObj[1];
        } else {
          dateObj = [];
          endTime = startTime = "";
        }
        labelType = name || "";
        ctx.emit("change", { startTime, endTime, name });
      } else {
        if (dateObj && dateObj.toString() !== "Invalid Date") {
          const newTime: number = dateObj.getTime();
          ctx.emit("change", newTime);
        } else {
          selectDate.value = new Date();
        }
      }
    };
    watch(props, (newProps) => {
      if (forceUpdateKey.value !== newProps.updateKey) {
        selectDate.value = newProps.defaultValue;
        forceUpdateKey.value = newProps.updateKey;
      }
      if (newProps.isReset) {
        init();
      }
    });

    return {
      selectDate,
      defaultTime,
      shortcuts,
      pickerOptions,
      dateChange,
    };
  },
});
