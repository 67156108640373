import CommonStore from "@/store/modules/common";
import { Iteration } from "@velites/common";
import { injectToStore, INJECT_SYMBOL_STORE_INITIALIZE } from "@velites/states";
import { ContainerModule, interfaces } from "inversify";
import { Store, StoreOptions } from "vuex";

export const mgrExtraStoresRegistryModule = new ContainerModule(
  (
    bind: interfaces.Bind,
    unbind: interfaces.Unbind,
    isBound: interfaces.IsBound,
    rebind: interfaces.Rebind
  ) => {
    bind<Iteration<Store<any> | StoreOptions<any>>>(
      INJECT_SYMBOL_STORE_INITIALIZE
    )
      .toDynamicValue((ctx) => ({
        name: "MGR-Common",
        tap: (s: Store<any> | StoreOptions<any>) =>
          injectToStore(s, CommonStore)
      }))
      .inSingletonScope();
  }
);
