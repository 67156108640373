
import { Page } from "@/utils/model/Page";
import { ComponentInternalInstance, getCurrentInstance, toRefs } from "vue";
import { defineComponent, reactive } from "vue-demi";
import AreaService, { Area, QueryAreaPage } from "./api/AreaService";
import UpsetArea from "./views/upsetArea.vue";

export default defineComponent({
  components: {
    UpsetArea,
  },
  props: {
    curUser: {
      type: Object,
    },
    terminal: {
      type: String,
      default: "",
    },
    visionLevel: {
      type: String,
      default: "",
    },
    areaManageCreatePerm: Array || String,
    areaManageEditPerm: Array || String,
  },
  emits: ["event"],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance() as ComponentInternalInstance;
    const areaService = new AreaService(props.terminal);
    const state = reactive({
      form: {
        queryName: "",
      },
      paginationInfo: {
        total: 0,
        pageSize: 10,
        current: 1,
      },
      listData: [] as Array<Area>,
    });
    const getQueryPageBody = (): QueryAreaPage => {
      const { current, pageSize } = state.paginationInfo;
      return {
        current,
        pageSize,
        ...state.form,
      };
    };
    const addArea = () => {
      const { pageSize, current } = state.paginationInfo;
      (proxy?.$refs.upsetArea as any).active({
        id: "",
        disabled: false,
        pageSize,
        current,
        ...state.form,
      });
    };
    const editArea = (row: Area) => {
      const { pageSize, current } = state.paginationInfo;
      (proxy?.$refs.upsetArea as any).active({
        ...row,
        pageSize,
        current,
        ...state.form,
      });
    };
    const openOrClose = async (row: Area) => {
      if (row.shopcount > 0) {
        emit("event", { type: "toShopManagement" });
        return;
      }
      const { pageSize, current } = state.paginationInfo;
      const res = await areaService.edit({
        ...row,
        disabled: !row.disabled,
        pageSize,
        current,
        ...state.form,
      });
      optionSuccess(res);
    };
    const optionSuccess = (page: Page<Area>) => {
      state.listData = page.content || [];
      state.paginationInfo.total = page.totalElements || 0;
      state.paginationInfo.current = page.number + 1;
    };
    const onSearch = async (current: number) => {
      state.paginationInfo.current = current;
      const page = await areaService.listByPage(getQueryPageBody());
      state.listData = page.content || [];
      state.paginationInfo.total = page.totalElements || 0;
      state.paginationInfo.current = page.number + 1;
    };
    const sizeChange = (size: number) => {
      state.paginationInfo.pageSize = size;
      onSearch(1);
    };
    const confimForm = (res: any) => {
      state.form = { ...res };
      onSearch(state.paginationInfo.current);
    };
    const tipString = (row: Area) => {
      return row.shopcount > 0
        ? "该区域下存在门店，请前往门店管理进行修改"
        : `此将${row.disabled ? "启用" : "停用"}区域，是否继续?`;
    };

    return {
      ...toRefs(state),
      onSearch,
      addArea,
      editArea,
      openOrClose,
      confimForm,
      tipString,
      optionSuccess,
      sizeChange,
    };
  },
  mounted() {
    this.onSearch(1);
  },
});
