export class User {
    id: string = "";
    username: string = "";
    displayName: string = "";
    mobile: string = "";
    roleName: string = "";
    disabled: boolean = false;
    disabledValue: string = "";
    currentUserTag: boolean = false;
  }

export class QueryData {
    account: string = "";
    roleId: string = "";
    page: number = 0;
    size: number = 10;
}