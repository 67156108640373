import { Pocket, PostMapping, RequestMapping, RequestParam, RestController, Download } from "@/common/decorators";
import { SearchResult } from "@ldos/shared-common/src";
import { KriMetricsSettingVO, KriMetricsSettingSearchVO, RiskRatingSettingObj } from "../vo";

const faker = require('faker');
import { v4 as uuidv4 } from 'uuid';
import { generateRandomArray } from "@ldos/shared-common/src/util/mock";
import moment from "moment";



export enum RiskTypeEnum {
  NORMAL = 'NORMAL',
  REVERSE_EVENT = 'REVERSE_EVENT'
}

type Dictionary<T> = {
  [key: string]: T;
};

const modules = [{ label: '模块1', value: '1' }, { label: '模块2', value: '2' }]
const kris = [{ label: 'kri1', value: '1', extra: { moduleId: '1' } }, { label: 'kri2', value: '2', extra: { moduleId: '1' } }, { label: 'kri3', value: '3', extra: { moduleId: '2' } }, { label: 'kri4', value: '4', extra: { moduleId: '2' } }]

export const generateKriMetricsSettingVO = (): KriMetricsSettingVO => {
  const id = uuidv4();
  const name = faker.lorem.words(2);
  const type = faker.random.arrayElement(Object.values(RiskTypeEnum));
  const riskModuleId = faker.random.arrayElement(modules)?.value || '';
  const riskModuleKriId = faker.random.arrayElement(kris.filter((it) => it.extra.moduleId === riskModuleId))?.value;
  const setting: Dictionary<RiskRatingSettingObj> = {};
  const weight = faker.random.number({ min: 1, max: 10 });
  const disabled = faker.random.arrayElement(Object.values(RiskTypeEnum));

  for (const i of ['1', '2', '3', '4', '5']) {
    setting[i] = {
      symbol1: faker.random.arrayElement(['moreThanOrEquals', 'moreThan']),
      symbol2: faker.random.arrayElement(['lessThanOrEquals', 'lessThan']),
      value1: faker.random.number(),
      value2: faker.random.number(),
    };
  }
  return {
    id,
    name,
    type,
    riskModuleId,
    riskModuleKriId,
    setting,
    collectColumn: faker.lorem.words(2),
    weight,
    disabled,
    updateTime: moment().valueOf(),
    createTime: moment().valueOf(),
    valueDisplayMode: 'PERCENTAGE',
  };
};



@RestController
@RequestMapping('/sys-kri-metrics')
export default class KriMetricsController {

  loadFlag = true;
  async search(req: KriMetricsSettingSearchVO): Promise<SearchResult<KriMetricsSettingVO>> {
    const reqFn = this.loadFlag ? this.load : this.page;
    this.loadFlag = false;
    return reqFn(req);
  }

  @Pocket(RiskTypeEnum, 'riskType')
  @Pocket(modules, 'module')
  @Pocket(kris, 'kri')
  @PostMapping('/load')
  async load(req: KriMetricsSettingSearchVO): Promise<SearchResult<KriMetricsSettingVO>> {
    return this.page(req);
  }

  @PostMapping('/page')
  async page(req: KriMetricsSettingSearchVO): Promise<SearchResult<KriMetricsSettingVO>> {
    return {
      totalElements: 23,
      hasMore: true,
      result: generateRandomArray(generateKriMetricsSettingVO),
    };
  }

  async upset(reqBody: KriMetricsSettingVO): Promise<Boolean> {
    const reqFn = reqBody.id ? this.update : this.create;
    return reqFn(reqBody);
  }

  @PostMapping('/add')
  async create(reqBody: KriMetricsSettingVO): Promise<Boolean> {
    return true;
  }

  @PostMapping('/edit')
  async update(reqBody: KriMetricsSettingVO): Promise<Boolean> {
    return true;
  }

  @Download
  @PostMapping("/download")
  async download(rptSubsidiaryParamVO: KriMetricsSettingSearchVO): Mono<void> {
    console.log(rptSubsidiaryParamVO)
  }

}
