import { RequestParam, RequestMapping, RestController, PostMapping, Download, Pocket } from "@/common/decorators";
import { RptSubsidiaryDownloadColumnEnum, RptSubsidiaryParamVO, RptSubsidiaryVO } from "../vo";


import { v4 as uuidv4 } from 'uuid';
import { generateRandomArray } from "@ldos/shared-common/src/util/mock";
import { CockpitYearVO, CockpitYearSubsidiaryVO } from "@/views/home/vo";
const faker = require('faker');

let yearC = 2021
function generateCockpitYearVO(): CockpitYearVO {
  const year: string = yearC + '';
  yearC++;
  const subsidiaryCount: number = faker.random.number({ min: 1, max: 5 });
  const subsidiaries: CockpitYearSubsidiaryVO[] = [];

  for (let i = 0; i < subsidiaryCount; i++) {
    const subsidiary: CockpitYearSubsidiaryVO = {
      id: uuidv4(),
      name: faker.company.companyName(),
      regionId: Math.random() > 0.5 ? '123' : '234',
    };
    subsidiaries.push(subsidiary);
  }

  return {
    year,
    region: [{ id: '123', name: '区域123', subsidiary: [] }, { id: '234', name: '区域234', subsidiary: [] }],
    subsidiary: subsidiaries
  };
}

@RestController
@RequestMapping("/mgr/rpt/subsidiary")
export default class RptSubsidiaryController {

  @Pocket(RptSubsidiaryDownloadColumnEnum, 'rptSubsidiaryDownloadColumn')
  @PostMapping("/year")
  async year(): Mono<CockpitYearVO[]> {
    return generateRandomArray(generateCockpitYearVO, 2) as CockpitYearVO[];
  }

  @PostMapping("/rpt")
  async rpt(rptSubsidiaryParamVO: RptSubsidiaryParamVO): Mono<RptSubsidiaryVO[]> {
    console.log(rptSubsidiaryParamVO)
    return {
      "data": [
        {
          "year": "2022",
          "companyName": "测试公司",
          "riskValue": 0.25,
          "riskLevel": 1,
          "moduleName": "市场秩序风险",
          "moduleWeight": 0.25,
          "moduleValue": 0.08,
          "moduleLevel": 1,
          "moduleKriName": "RKRI",
          "moduleKriWeight": 0.80,
          "moduleKriValue": 0.10,
          "kriMetricsName": "KA-β指数",
          "kriMetricsWeight": 0.10,
          "kriMetricsValue": -4.86,
          "kriMetricsLevel": 1
        },
        {
          "year": "2022",
          "companyName": "测试公司",
          "riskValue": 0.25,
          "riskLevel": 1,
          "moduleName": "市场秩序风险",
          "moduleWeight": 0.25,
          "moduleValue": 0.08,
          "moduleLevel": 1,
          "moduleKriName": "RKRI",
          "moduleKriWeight": 0.80,
          "moduleKriValue": 0.10,
          "kriMetricsName": "KA-A指数",
          "kriMetricsWeight": 0.10,
          "kriMetricsValue": -4.86,
          "kriMetricsLevel": 1
        },
        {
          "year": "2022",
          "companyName": "测试公司",
          "riskValue": 0.25,
          "riskLevel": 1,
          "moduleName": "市场秩序风险",
          "moduleWeight": 0.25,
          "moduleValue": 0.08,
          "moduleLevel": 1,
          "moduleKriName": "CKRI",
          "moduleKriWeight": 0.80,
          "moduleKriValue": 0.10,
          "kriMetricsName": "CKRI-1",
          "kriMetricsWeight": 0.10,
          "kriMetricsValue": -4.86,
          "kriMetricsLevel": 1
        },
        {
          "year": "2022",
          "companyName": "测试公司",
          "riskValue": 0.25,
          "riskLevel": 1,
          "moduleName": "市场秩序风险",
          "moduleWeight": 0.25,
          "moduleValue": 0.08,
          "moduleLevel": 1,
          "moduleKriName": "CKRI",
          "moduleKriWeight": 0.80,
          "moduleKriValue": 0.10,
          "kriMetricsName": "CKRI-2",
          "kriMetricsWeight": 0.10,
          "kriMetricsValue": -4.86,
          "kriMetricsLevel": 1
        },
        {
          "year": "2022",
          "companyName": "测试公司",
          "riskValue": 0.25,
          "riskLevel": 1,
          "moduleName": "另一个风险风险",
          "moduleWeight": 0.25,
          "moduleValue": 0.08,
          "moduleLevel": 1,
          "moduleKriName": "CKRI",
          "moduleKriWeight": 0.80,
          "moduleKriValue": 0.10,
          "kriMetricsName": "另一个风险风险指标",
          "kriMetricsWeight": 0.10,
          "kriMetricsValue": -4.86,
          "kriMetricsLevel": 1
        }
      ],
      "timestamp": 1711356675411
    }.data as any;
  }

  @Download
  @PostMapping("/download")
  async download(rptSubsidiaryParamVO: RptSubsidiaryParamVO): Mono<void> {
    console.log(rptSubsidiaryParamVO)
  }
  @Download
  @PostMapping("/allSubsidiarySimpleDownload")
  async allSubsidiarySimpleDownload(rptSubsidiaryParamVO: RptSubsidiaryParamVO): Mono<void> {
    console.log(rptSubsidiaryParamVO)
  }
}
