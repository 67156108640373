import { PostMapping, RequestMapping, RestController } from "@/common/decorators";
import { RiskRatingSettingVO } from "../vo";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";



@RestController
@RequestMapping('/sys-risk-rating')
export default class RiskRatingController {

  async search(): Promise<List<RiskRatingSettingVO>> {
    return this.load();
  }

  @PostMapping('/load')
  async load(): Promise<List<RiskRatingSettingVO>> {
    return []
    // return [{ id: uuidv4(), code: 'MKDJSZ', name: '模块定级', setting: { "1": { "symbol1": "moreThanOrEquals", "value1": 0, "symbol2": "lessThan", "value2": 1 }, "2": { "symbol1": "moreThanOrEquals", "value1": 1, "symbol2": "lessThan", "value2": 2 }, "3": { "symbol1": "moreThanOrEquals", "value1": 2, "symbol2": "lessThan", "value2": 3 }, "4": { "symbol1": "moreThanOrEquals", "value1": 3, "symbol2": "lessThan", "value2": 4 }, "5": { "symbol1": "moreThanOrEquals", "value1": 4, "symbol2": "lessThan", "value2": 5 } }, updateTime: moment().valueOf() }];
  }

  @PostMapping('/edit')
  async update(reqBody: RiskRatingSettingVO): Promise<Boolean> {
    return true;
  }

}