export enum RmRiskWarnTypeEnum {
    REGION = 'REGION',
    SUBSIDIARY = 'SUBSIDIARY',
    MODULE = 'MODULE',
    KRI_METRICS = 'KRI_METRICS'
}

export enum RmRiskWarnStatusEnum {
    PENDING = 'PENDING',
    CLOSED = 'CLOSED',
    TEMP = 'TEMP',
    FOLLOW = 'FOLLOW',
    UNABLE = 'UNABLE'
}