
import { defineComponent } from "vue";
interface IConfig {
  imgApi: string;
}
export default defineComponent({
  name: "mas-preview-client",
  components: {},
  props: {
    name: {
      type: String,
      default: "I营销",
    },
    config: {
      type: Object as () => IConfig,
    },
    list: {
      type: Object,
      default: {},
    },
  },
  emits: [],
  setup(prop, { emit }) {},
});
