const parentMenuKey = "report";
export default [
  {
    meta: {
      permissionCode: "Mgr.Rpt.Subsidiary.View",
      title: "分公司报表",
      parentMenuKey
    },
    icon: "",
    path: "/report/subsidiary-report",
    component: () => import("@/views/report/views/subsidiary-report/index.vue")
  },
  
];