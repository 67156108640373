import {
  AxiosRequestConfig,
} from "axios";
import stubs from '@/init';
import { Dictionary } from 'lodash';
import { decorate, injectable } from 'inversify';
import 'reflect-metadata';
import { ApiOptions } from "@velites/api";
import { downloadByteArraystream } from "@ldos/shared-common/src/util/utilsHandle";
import { ElMessage } from "element-plus";
import { FACET_TYPE_POCKET, FacetStorage, INJECT_SYMBOL_FACET_STORAGE } from "@velites/facet";
import { Nullable, SimpleItem } from "@velites/common";

export function RequestParam(name: string) {
  return function (target: any, propertyKey: string, parameterIndex: number) {
    const existingParamNames = Reflect.getMetadata('custom:parameterNames', target, propertyKey) || [];
    const paramNames = [...existingParamNames, name];
    Reflect.defineMetadata('custom:parameterNames', paramNames, target, propertyKey);
  };
}


const getPrototypeOf = (function () {
  const map = new Map<Function, Dictionary<any>>();
  return function (key: any) {
    if (!map.has(key)) {
      map.set(key, {});
    }
    return map.get(key) || {};
  }
})()

export function LogisticValue(value: string) {
  return function (target: any, propertyKey: string) {
    Object.defineProperty(target, propertyKey, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: false
    });
  };
}

export function RestController(target: any) {
  decorate(injectable(), target);
  return target;
}

export function RequestMapping(baseUrl: string) {
  if (!baseUrl.startsWith('/')) {
    baseUrl = '/' + baseUrl;
  }
  return function (target: any) {
    const methodNames = Object.getOwnPropertyNames(target.prototype);
    methodNames.forEach(methodName => {
      const descriptor = Object.getOwnPropertyDescriptor(target.prototype, methodName);
      if (descriptor && typeof descriptor.value === 'function') {
        getPrototypeOf(descriptor.value).baseUrl = baseUrl;
      }
    });
  }
}

function enumToPocket(obj: any, pocketName: string): Array<SimpleItem> {
  if (!obj || typeof obj !== 'object' || obj === null) return [];
  return Object.keys(obj).map((key) => ({ label: `*${obj[key]}*`, value: obj[key] }))
}

export function PostMapping(url: string, mock = false) {
  if (!url.startsWith('/')) {
    url = '/' + url;
  }
  return function (target: any, key: string | symbol, descriptor: PropertyDescriptor) {
    const originFn = descriptor.value;
    if (mock) {
      const originFn = descriptor.value;
      descriptor.value = async function (...args: any[]) {
        const facetStorage: FacetStorage = stubs.injector.get(INJECT_SYMBOL_FACET_STORAGE);
        const pockets = getPrototypeOf(descriptor.value).pockets || {}
        for (const key of Object.keys(pockets)) {
          if (pockets[key] instanceof Array) {
            facetStorage.saveFacet(FACET_TYPE_POCKET, key, pockets[key])
          } else {
            facetStorage.saveFacet(FACET_TYPE_POCKET, key, enumToPocket(pockets[key], key))
          }
        }
        return originFn.apply(target, args);
      }
      return;
    }
    const paramNames = [...Reflect.getMetadata('custom:parameterNames', target, key) || []].reverse();
    descriptor.value = async function (...args: any[]) {
      let reqBody: Object | null = null;
      let extraOptions = {} as AxiosRequestConfig;
      const reqParams: Dictionary<string> = {};
      if (getPrototypeOf(descriptor.value).extra?.baseURL) {
        extraOptions.baseURL = getPrototypeOf(descriptor.value).extra?.baseURL || '';
      }
      let index = 0;
      args.forEach((arg) => {
        if (typeof arg === 'object' && arg !== null) {
          if (reqBody !== null) {
            extraOptions = { ...extraOptions, ...arg };
          } else {
            reqBody = arg;
          }
        } else {
          reqParams[paramNames[index++]] = arg;
        }
      })
      let xUrl = getPrototypeOf(descriptor.value).baseUrl + url;
      if (xUrl.startsWith('/')) {
        xUrl = xUrl.substring(1, xUrl.length);
      }
      const resourceCode = xUrl.replaceAll('/', '_');
      extraOptions.headers && (extraOptions.headers = {})
      extraOptions.headers = { ...extraOptions.headers, ...getPrototypeOf(descriptor.value).headers || {}, 'x-resource-code': resourceCode }
      let actualUrl = url;
      if (Object.keys(reqParams).length) {
        actualUrl += ('?' + Array.from(Object.entries(reqParams)).map(([key, value]) => `${key}=${value}`).join('&'));
      }
      if (getPrototypeOf(descriptor.value).extra?.download) {
        const requests: ApiOptions = {
          method: "post",
          url: getPrototypeOf(descriptor.value).baseUrl + actualUrl,
          data: reqBody || {},
          responseType: "blob",
          ...extraOptions,
        };
        const response = await stubs.api.requestRaw(requests);
        if (response && response.status === 200) {
          downloadByteArraystream(response);
          ElMessage({
            type: "success",
            message: "下载成功"
          });
        } else {
          ElMessage({
            type: "error",
            message: "下载失败"
          });
        }
        return Promise.resolve();
      }
      const [data] = await stubs.api.post(getPrototypeOf(descriptor.value).baseUrl + actualUrl, reqBody || {}, extraOptions);
      return Promise.resolve(data);
    };
    getPrototypeOf(descriptor.value).headers = getPrototypeOf(originFn).headers;
    getPrototypeOf(descriptor.value).extra = getPrototypeOf(originFn).extra;
    getPrototypeOf(descriptor.value).pockets = getPrototypeOf(originFn).pockets;
    getPrototypeOf(descriptor.value).url = url;
  };
}

export function ResourceCode(permissionCode: string) {
  return function (target: any, key: string | symbol, descriptor: PropertyDescriptor) {
    if (typeof descriptor.value === 'function') {
      getPrototypeOf(descriptor.value).headers || (getPrototypeOf(descriptor.value).headers = {});
      getPrototypeOf(descriptor.value).headers['x-resource-code'] = permissionCode;
    }
  };
}

export function Pocket(enumObj: any, pocketName: string) {
  return function (target: any, key: string | symbol, descriptor: PropertyDescriptor) {
    if (typeof descriptor.value === 'function') {
      getPrototypeOf(descriptor.value).pockets || (getPrototypeOf(descriptor.value).pockets = {});
      getPrototypeOf(descriptor.value).pockets[pocketName] = enumObj;
    }
  };
}

export function Download(target: any, key: string | symbol, descriptor: PropertyDescriptor) {
  if (typeof descriptor.value === 'function') {
    (getPrototypeOf(descriptor.value).extra = {}) || (getPrototypeOf(descriptor.value).extra = {});
  }
  getPrototypeOf(descriptor.value).extra.download = true;
  getPrototypeOf(descriptor.value).extra.baseURL = '/api'
}

export function ExtraOptions(options: ApiOptions) {
  return function (target: any, key: string | symbol, descriptor: PropertyDescriptor) {
    if (typeof descriptor.value === 'function') {
      getPrototypeOf(descriptor.value).extraOptions || (getPrototypeOf(descriptor.value).extraOptions = options);
    }
  };
}