import stubs from "@/init";
import { Page } from "@/utils/model/Page";
export default class AreaService {
  baseUrl: string = "";
  constructor(terminal: string) {
    this.baseUrl = `/${terminal}/area`
  }

  async listByPage(body: QueryAreaPage): Promise<Page<Area>> {
    const [data] = await stubs.api.post(`${this.baseUrl}/listbypage`, body);
    return data;
  }

  async add(body: Area): Promise<Page<Area>> {
    const [data] = await stubs.api.post(`${this.baseUrl}/add`, body);
    return data;
  }

  async edit(body: Area): Promise<Page<Area>> {
    const [data] = await stubs.api.post(`${this.baseUrl}/edit`, body);
    return data;
  }
}

export declare type Area = {
  id: string;
  tenantId: string;
  name: string;
  queryName: string;
  current: number;
  pageSize: number;
  disabled: boolean;
  version: number;
  shopcount: number;
};

export declare type QueryAreaPage = {
  queryName: string;
  current: number;
  pageSize: number;
};
