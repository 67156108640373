
import { ApiService, INJECT_SYMBOL_API_INSTANTIATED } from '@velites/api';
import { Iteration, SimpleItem } from '@velites/common';
import { INJECT_SYMBOL_FACET_STORAGE, FacetStorage } from '@velites/facet'
import { InitPluginOptions } from '@velites/init';
import { ContainerModule, injectable, interfaces, inject } from 'inversify';
import lodash, { Dictionary } from 'lodash';
import { App } from 'vue';

export interface DynamicPropertySelectionItem {
  label: string;
  value: any;
}
export interface DynamicPropertyValidationRule {
  type: string;
  rule: string;
  errorMsg: string;
}
export interface DynamicPropertyDef {
  name: string;
  templateCode?: string;
  description?: string;
  valueType: string;
  displayName: string;
  displayType: string;
  hintMsg?: string;
  showInList?: boolean;
  disabled?: boolean;
  validations?: Array<DynamicPropertyValidationRule>;
  selections?: Array<SimpleItem>;
}
export interface DynamicEntityDef {
  entity: string;
  properties?: DynamicPropertyDef[];
}

export const FACET_TYPE_DYNAMIC = 'dynamic'
export const INSTANCIATED_API_NAME_DYNAMIC = 'Dynamic'
export const TAP_PROCESS_NAME_API_SUCCEEDED_DYNAMIC = 'Dynamic'

declare module '@velites/api' {
  export interface ApiResult {
    dynamics?: Dictionary<DynamicEntityDef>;
  }
}

@injectable()
class DynamicApiInstanciated implements Iteration<[void, ApiService]> {

  constructor(
    @inject(INJECT_SYMBOL_FACET_STORAGE) private storage: FacetStorage
  ) {
  }

  name: string = FACET_TYPE_DYNAMIC;

  tap(_: void, api: ApiService) {
    api.tapSucceeded({
      name: TAP_PROCESS_NAME_API_SUCCEEDED_DYNAMIC
    }, result => {
      const ps = result?.dynamics;
      if (ps) {
        lodash.forOwn(ps, (v, k) => {
          this.storage.saveFacet(FACET_TYPE_DYNAMIC, k, v);
        });
      }
      return result;
    })
  }
}

/**
 * 提供动态字段定义的便利消费类
 */
@injectable()
export class DynamicConsumer {
  constructor(
     @inject(INJECT_SYMBOL_FACET_STORAGE) private storage: FacetStorage
   ) {
  }
   
  obtainEntityDef(key: string): DynamicEntityDef | null {
    return this.storage.obtainFacet<DynamicEntityDef>(FACET_TYPE_DYNAMIC, key)
  }
 
  /**
  * 
  * @param key 
  * @param options.includingDisabled 指定是否要包含禁用（@link {DynamicPropertyDef.disabled}）的字典项，默认为不包含
  * @param options.filter 如果不为空，则返回集合前会用其删选
  */
  obtainPropertyDefs(key: string, options?: { includingDisabled?: boolean, filter?: (p: DynamicPropertyDef) => boolean}): Array<DynamicPropertyDef> {
    let defs = this.obtainEntityDef(key)?.properties;
    if (!defs) {
    defs = [];
    } else if (!options?.includingDisabled) {
      defs = defs.filter(p => !p.disabled);
    }
    if (options?.filter) {
      defs = defs.filter(options.filter);
    }
    return defs;
  }
}


@injectable()
export class DynamicVuePlugin {
  constructor(
    @inject(DynamicConsumer) private consumer: DynamicConsumer
  ) {
  }

  install = (app: App) => {
    app.mixin({
      methods: {
        dynamicEntity: (key: string): DynamicEntityDef | null => {
          return this.consumer.obtainEntityDef(key);
        },
        dynamicProperties: (key: string, options?: { includingDisabled?: boolean, filter?: (item: SimpleItem) => boolean}): Array<DynamicPropertyDef> => {
          return this.consumer.obtainPropertyDefs(key, options);
        }
      }
    });
  }
}
 
export const dynamicRegistryModule = new ContainerModule(
  (bind: interfaces.Bind, unbind: interfaces.Unbind, isBound: interfaces.IsBound, rebind: interfaces.Rebind) => {
    bind<Iteration<[void, ApiService]>>(INJECT_SYMBOL_API_INSTANTIATED).to(DynamicApiInstanciated).inSingletonScope();
    bind<DynamicApiInstanciated>(DynamicApiInstanciated).toSelf().inSingletonScope();
    bind<DynamicConsumer>(DynamicConsumer).toSelf().inSingletonScope();
    bind(DynamicVuePlugin).toSelf().inSingletonScope();
  }
)

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    dynamicEntity(key: string): DynamicEntityDef | null;
    dynamicProperties: (pocketKey: string, options?: { includingDisabled?: boolean, filter?: (item: SimpleItem) => boolean}) => Array<DynamicPropertyDef>;
  }
}

declare module '@velites/init' {
  export interface InitPluginType {
    dynamics?: DynamicConsumer;
  }
}

export function dynamicInitialVuePluginOptionsDecorators(opt: InitPluginOptions): void {
  if (!opt.moreInjectionModules) {
    opt.moreInjectionModules = []
  }
  opt.moreInjectionModules.push(dynamicRegistryModule);
  if (!opt.moreExtensions) {
    opt.moreExtensions = []
  }
  opt.moreExtensions.push((container, opt, ext) => {
    if (!opt.moreVuePlugins) {
      opt.moreVuePlugins = []
    }
    opt.moreVuePlugins.push(container.get(DynamicVuePlugin));
    return { dynamics: container.get(DynamicConsumer) };
  });
}
