
export default {
  name: 'Ellipsis',
  props: {
    lines: {
      type: Number,
      default: 1
    },
    width: {
      type: Number,
      default: 200
    }
  }
};
