
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    placeholder: {
      type: String,
      default: () => ''
    }
  }
})
export default class extends Vue {
  expandState = true;
  changeExpandState() {
    this.expandState = !this.expandState;
  }
}
