import { WSWrapper } from "@velites/api";
import {
  EventHub,
  EVENT_TOPIC_LOGGED_IN,
  EVENT_TOPIC_LOGGED_OUT,
  EVENT_TOPIC_LOGGING_RESUMED
} from "@velites/event";
import { InitPluginExtension, WSSessions } from "@velites/init";

export const wsInitPluginExtension: InitPluginExtension = (
  container: any,
  opt: any,
  ext: { event: EventHub; ws: { wrappers: any } }
) => {
  (<EventHub>ext.event).subscribeEvent(
    [EVENT_TOPIC_LOGGED_IN, EVENT_TOPIC_LOGGING_RESUMED],
    () => (<WSSessions>ext.ws.wrappers).normal.open()
  );
  (<EventHub>ext.event).subscribeEvent([EVENT_TOPIC_LOGGED_OUT], () =>
    (<WSSessions>ext.ws.wrappers).normal.close()
  );
};

declare module "@velites/init" {
  export interface WSSessions {
    normal: WSWrapper;
  }
}
