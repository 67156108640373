
import { computed, defineComponent } from "vue";

export default defineComponent({
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object as () => { startTime: number | Date | undefined, endTime: number | Date | undefined },
      default: {
        startTime: undefined,
        endTime: undefined,
      }
    }
  },
  setup(props, { emit }) {
    const value = computed(() => {
      const { startTime, endTime } = props.modelValue;
      return [startTime, endTime]
        .map(it => typeof it === 'number' ? new Date(it) : it)
        .filter(it => it);
    })
    const change = (value: Array<Date | undefined>) => {
      const [startDate, endDate] = value || [];
      emit('update:modelValue', {
        startTime: startDate ? startDate.getTime() : undefined,
        endTime: endDate ? endDate.getTime() : undefined
      })
    }
    return {
      change,
      value
    }
  }
})
