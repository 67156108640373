
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    title: {
      type: String,
      default: "暂无数据",
    },
  },
})
export default class extends Vue {
  title!: string;
}
