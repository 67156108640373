import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import home from './home';
import menuTree from './modules/index';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login', // 重定向登录页面
    meta: {
      menuGroup: false,
    },
  },
  ...home,
  {
    path: '/',
    meta: {
      menuGroup: false,
      bussiness: true,
    },
    component: () => import('@/views/common/index.vue'),
    children: [
      {
        path: '/',
        meta: {
          menuGroup: false,
          bussiness: true,
        },
        component: () => import('@/views/layout/Layout.vue'),
        children: [...menuTree],
      },
      {
        path: '/login',
        name: 'login',
        meta: {
          menuGroup: false,
        },
        component: () => import('@/views/login.vue'),
      },
    ],
  },
  {
    path: '/demo/test1',
    name: 'demo-test1',
    meta: {
      menuGroup: false,
    },
    component: () => import('@/views/demo/test1.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    meta: {
      menuGroup: false,
    },
    component: () => import('@/views/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((from, to, next) => {
  if (typeof from.meta?.headTitle === 'string') {
    document.title = from.meta.headTitle;
  } else {
    document.title = '审计风控平台';
  }
  next();
});

export default router;
