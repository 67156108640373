
import { Options, Vue } from "vue-class-component";
import * as echarts from "echarts";
import _ from "lodash";
import { IEchartHeaderOption } from "./part/interface";
import EchartRightBar from "./part/echart-right-bar.vue";
@Options({
  name: "MasEchart",
  components: {
    EchartRightBar,
  },
  props: {
    title: {
      type: String,
    },
    optionConfig: {
      type: Object,
    },
    echartHeaderOption: {
      type: Array as () => IEchartHeaderOption[],
    },
  },
  watch: {
    screenWidth() {
      const wid = this.$refs.optPic.offsetWidth + "px";
      if (this.chart) {
        this.chart.resize({ width: wid });
      }
    },
    optionConfig: {
      handler() {
        this.setEchartInfo(this.optionConfig);
      },
      deep: true,
    },
  },
})
export default class masEchart extends Vue {
  chart: any; // echart图表源
  echartHeaderOption!: IEchartHeaderOption[];
  screenWidth = 0; // 定义屏幕宽度
  optionConfig!: any;
  title!: string;
  showNoData = false;

  mounted(): void {
    this.setEhartSize();
  }

  setEhartSize() {
    const that = this;
    this.$nextTick(() => {
      that.init();
      that.screenWidth = document.body.clientWidth;
      window.onresize = () => {
        return (() => {
          that.screenWidth = document.body.clientWidth;
        })();
      };
    });
  }

  init(): void {
    // 图表初始化
    const chartDom = document.getElementById("chart");

    if (!chartDom) {
      console.log("选择器 #chart 对应的dom不存在");
      return;
    }

    const width = getComputedStyle(chartDom).width;
    const size = {
      width: parseFloat(width),
      height: 420,
    };
    this.chart = echarts.init(this.$refs.chart as HTMLCanvasElement, "red", size);
  }

  setEchartInfo(option: any) {
    this.chart && this.chart.clear();
    if (_.isEmpty(this.optionConfig)) {
      this.showNoData = true;
      console.log("echart暂无配置");
    } else {
      setTimeout(() => {
        this.showNoData = false;
        this.chart && this.chart.setOption(option, true);
      }, 500);
    }
  }
}
