const parentMenuKey = "configuration";
export default [
  {
    meta: {
      permissionCode: "Mgr.System.Region.View",
      title: "大区管理",
      parentMenuKey
    },
    icon: "",
    path: "/configuration/region",
    component: () => import("@/views/config/views/region/index.vue")
  },
  {
    meta: {
      permissionCode: "Mgr.System.Subsidiary.View",
      title: "分公司管理",
      parentMenuKey
    },
    icon: "",
    path: "/configuration/subsidiary-setting",
    component: () => import("@/views/config/views/subsidiary-setting/index.vue")
  },
  {
    meta: {
      permissionCode: "Mgr.System.KriMetrics.View",
      title: "kri指标管理",
      parentMenuKey
    },
    icon: "",
    path: "/configuration/kri-metrics-management",
    component: () => import("@/views/config/views/kri-metrics-management/index.vue")
  },
  {
    meta: {
      permissionCode: "Mgr.System.Weight.View",
      title: "设置权重",
      parentMenuKey
    },
    icon: "",
    path: "/configuration/weight-setting",
    component: () => import("@/views/config/views/weight-setting/index.vue")
  },
  {
    meta: {
      permissionCode: "Mgr.System.RiskLevelSettings.View",
      title: "风险定级设置",
      parentMenuKey
    },
    icon: "",
    path: "/configuration/risk-rating-setting",
    component: () => import("@/views/config/views/risk-rating-setting/index.vue")
  },
  {
    meta: {
      permissionCode: "Mgr.System.User.View",
      title: "用户管理",
      parentMenuKey
    },
    icon: "",
    path: "/configuration/users-setting",
    component: () => import("@/views/config/views/users/index.vue")
  },
];

