
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    direction: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
    },
  },
})
export default class extends Vue {}
