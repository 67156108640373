
import CommonStore from "@/store/modules/common";
import { setEncryptionPhone } from "@/utils/utilsHandle";
import { Options, Vue } from "vue-class-component";
import { ACCOUNT_EXTENSION_KEY_CMVCC_SEAT, CmvccSeat } from "@ldos/shared-common";
interface ISelectConfig {
  id: string;
  name: string;
  firstMobileNumber: string;
  secondMobileNumber: string;
  statusId: string;
  openSource?: string;
  mobileHideState: number;
  callState: number;
  querys: any;
}
@Options({})
export default class extends Vue {
  selectMobileModalState = false; // 选择外呼号码弹层 是否启动

  currentActiveObj: ISelectConfig = {
    id: "", // id
    name: "", // 姓名
    statusId: "", // 线索状态
    firstMobileNumber: "", // 第一个手机号
    secondMobileNumber: "", // 第二个手机号
    openSource: "LIST", // 调起外呼的有河面来源 【LIST | DETAILS】
    mobileHideState: 0, // 根据渠道大类隐藏
    callState: 0, // 根据通话状态隐藏
    querys: {}, // 保存并下一条所需的查询参数
  }; // 当前激活的对象

  get getState() {
    return this.$stores.retrieveStore(CommonStore).getCallState;
  }

  get user(): any {
    return this.obtainAccount();
  }

  active(res: any): void {
    this.currentActiveObj = { ...res };
    // 外呼只有留资号码时，不弹框，直接呼出
    if (!this.currentActiveObj.secondMobileNumber) {
      this.dialMobile(this.currentActiveObj.firstMobileNumber, this.currentActiveObj, 1);
    } else {
      this.selectMobileModalState = true;
    }
  }

  setEncryptionPhoneText(s: string) {
    return setEncryptionPhone(s);
  }

  dialMobile(v: string | number, o: ISelectConfig, t: number): void | boolean {
    if (!this.getState && this.$account.retrieveExtension<CmvccSeat>(ACCOUNT_EXTENSION_KEY_CMVCC_SEAT)?.agentId) {
      this.$event.publishEvent("api.agent.state", true);
      this.$Message({
        type: "warning",
        message: "坐席已离线, 请重新登录",
      });
      return false;
    }
    this.$event.publishEvent("api.call.mobile", { v, o, t });
    this.selectMobileModalState = false;
  }
}
