<template>
  <div class="home">
    <transition @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter">
      <div class="ball" v-show="show" :style="{transition: `all ${endTime/1000}s cubic-bezier(0.49, -0.29, 1, 1)`}">
        <div class="innerBall" :style="{transition: `all ${endTime/1000}s linear`}">
          <el-icon color="#159AFF" style="transform: scale(1.5)"><Link /></el-icon>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "AnimationBall",
  props: {
    startEl: {
      type: Object,
    },
  },
  data() {
    return {
      el: null,
      show: false,
      endTime: 1000
    };
  },
  methods: {
    beforeEnter(el) {
      let ract = this.el.getBoundingClientRect(); //开始的位置
      let elRight = this._getStyle(el, "left"); //目标 right
      let elTop = this._getStyle(el, "top"); //目标 top
      let x = ract.left - parseFloat(elRight); // 计算小球移动的X轴的距离
      let y = ract.top - parseFloat(elTop); // 计算小球移动的y轴的距离
      el.style.display = "";
      el.style.transform = `translateY(${y}px)`; //重置小球的x轴位置
      let innerBall = el.querySelector(".innerBall");
      innerBall.style.transform = `translateX(${x}px)`; //重置小球的y轴位置
    },
    enter(el, done) {
      this._offset = document.body.offsetHeight; //激发重绘
      el.style.transform = `translate(0, 0)`; //小球沿着y轴移动到目标位置
      let innerBall = el.querySelector(".innerBall");
      innerBall.style.transform = `translate(0, 0)`; //小球沿着x轴移动到目标位置
      el.addEventListener("transitionend", done);
    },
    afterEnter(el) {
      this.show = false;
      el.style.display = "none";
    },
    start(e,left, top, endTime=1000) {
      let ball = document.querySelector(".ball");
      ball.style.left = left + "px";
      ball.style.top = top + "px";
      this.el = e.target;
      this.show = true;
      this.endTime = endTime;
    },
    _getStyle(el, attr) {
      return el.currentStyle ? el.currentStyle[attr] : getComputedStyle(el, false)[attr];
    },
  },
};
</script>
<style lang="scss">
.cart,
.ball {
  position: fixed;
  z-index: 300000;
}
.cart {
  border: 1px solid blue;
  padding: 5px 12px;
}
.ball {
  width: 100px;
  height: 100px;
  
}
.innerBall {
  width: 10px;
  height: 10px;
}
</style>
