import { RequestParam, RequestMapping, RestController, PostMapping, Download, Pocket } from "@/common/decorators";

import { v4 as uuidv4 } from 'uuid';
import { generateRandomArray } from "@ldos/shared-common/src/util/mock";
import { CockpitYearVO, CockpitYearSubsidiaryVO } from "@/views/home/vo";
import { RmRiskWarnTypeEnum, RmRiskWarnStatusEnum } from "../vo/enum";
import { RmRiskWarnSearchParamVO } from "../vo/RmRiskWarnSearchParamVO";
import { SearchResult } from "@@/../shared-common/src";
import { RmRiskWarnSearchResultVO } from "../vo/RmRiskWarnSearchResultVO";
import { RmRiskWarnUpdateStatusParamVO } from "../vo/RmRiskWarnUpdateStatusParamVO";
const faker = require('faker');

let yearC = 2021
function generateCockpitYearVO(): CockpitYearVO {
  const year: string = yearC + '';
  yearC++;
  const subsidiaryCount: number = faker.random.number({ min: 1, max: 5 });
  const subsidiaries: CockpitYearSubsidiaryVO[] = [];

  for (let i = 0; i < subsidiaryCount; i++) {
    const subsidiary: CockpitYearSubsidiaryVO = {
      id: uuidv4(),
      name: faker.company.companyName(),
      regionId: ""
    };
    subsidiaries.push(subsidiary);
  }

  return {
    year,
    region: [],
    subsidiary: subsidiaries
  };
}


@RestController
@RequestMapping("/mgr/rpt/rmRiskWarn")
export default class RptSubsidiaryController {

  @Pocket(RmRiskWarnStatusEnum, 'rmRiskWarnStatusEnum')
  @Pocket(RmRiskWarnTypeEnum, 'rmRiskWarnTypeEnum')
  @PostMapping("/year")
  async year(): Mono<CockpitYearVO[]> {
    return generateRandomArray(generateCockpitYearVO, 2) as CockpitYearVO[];
  }



  async search(param: RmRiskWarnSearchParamVO): Promise<SearchResult<RmRiskWarnSearchResultVO>> {
    return this.page(param);
  }


  @PostMapping("/load")
  async load(param: RmRiskWarnSearchParamVO): Promise<SearchResult<RmRiskWarnSearchResultVO>> {
    return this.page(param);
  }

  @PostMapping("/page")
  async page(param: RmRiskWarnSearchParamVO): Promise<SearchResult<RmRiskWarnSearchResultVO>> {
    return {
      totalElements: 13,
      result: generateRandomArray(getRmRiskWarnSearchResultVO),
      hasMore: true,
    };
  }

  @PostMapping("/updateStatus")
  async updateStatus(generateSubsidiaryParamVO: RmRiskWarnUpdateStatusParamVO): Promise<Boolean> {
    return true;
  }

  @Download
  @PostMapping("/download")
  async download(param: RmRiskWarnSearchParamVO): Promise<void> {
    return
  }
}

function getRmRiskWarnSearchResultVO(): RmRiskWarnSearchResultVO {
  return {
    id: '111',
    year: '2023',
    regionId: '123',
    regionName: 'East Region',
    companyId: '456',
    companyName: 'Example Company',
    type: RmRiskWarnTypeEnum.SUBSIDIARY,
    warnModuleId: '789',
    warnModuleName: 'Example Module',
    riskMetricsId: '987',
    riskMetricsName: 'Example Metrics',
    riskValue: 10.5,
    status: RmRiskWarnStatusEnum.PENDING,
    riskLevel: 3,
    compareRiskLevel: 2
  };
}