export default [
  {
    meta: {
      permissionCode: "Mgr.Staff.Manage",
      title: "零售业务部大屏",
      headTitle: "零售业务部大屏",
    },
    path: "/home",
    component: () => import("@/views/home/index.vue")
  },
  {
    meta: {
      permissionCode: "Mgr.Staff.Manage",
      title: "区域",
      headTitle: "大区大屏",
    },
    path: "/region",
    component: () => import("@/views/home/region.vue")
  },
  {
    meta: {
      permissionCode: "Mgr.Staff.Manage",
      title: "分公司大屏",
      headTitle: "分公司大屏",
    },
    path: "/subsidiary",
    component: () => import("@/views/home/subsidiary.vue")
  },
]