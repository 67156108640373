import {
  RequestMapping,
  RestController,
  PostMapping
} from "@/common/decorators";
import { RiskTypeEnum, WeightSettingVO } from "../vo";

@RestController
@RequestMapping("/mgr/sys/weight")
export default class WeightSettingController {

  @PostMapping("/load")
  async load(): Promise<WeightSettingVO[]> {
    // Implement load method
    return [{
      sysRiskModuleId: '1',
      sysRiskModuleName: '市场秩序风险',
      sysRiskModuleWeight: 0.5,
      sysRiskModuleKriId: '1',
      sysRiskModuleKriName: 'RKRI',
      sysRiskModuleKriWeight: 0.8,
      sysRiskModuleKriType: RiskTypeEnum.NORMAL,
    }, {
      sysRiskModuleId: '1',
      sysRiskModuleName: '市场秩序风险',
      sysRiskModuleWeight: 0.5,
      sysRiskModuleKriId: '1',
      sysRiskModuleKriName: 'CKRI',
      sysRiskModuleKriWeight: 0.2,
      sysRiskModuleKriType: RiskTypeEnum.NORMAL,
    }, {
      sysRiskModuleId: '1',
      sysRiskModuleName: '市场秩序风险',
      sysRiskModuleWeight: 0.5,
      sysRiskModuleKriId: '1',
      sysRiskModuleKriName: '逆向事件',
      sysRiskModuleKriWeight: -1,
      sysRiskModuleKriType: RiskTypeEnum.NORMAL,
    }] as WeightSettingVO[];
  }

  @PostMapping("/set")
  async set(list: WeightSettingVO[]): Promise<WeightSettingVO[]> {
    // Implement set method
    return [] as WeightSettingVO[];
  }
}
