import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["common-high-tab-bar", { small: _ctx.small }])
  }, [
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
      stretch: true,
      "tab-position": "top",
      onTabClick: _ctx.handleClick
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formatList, (item, index) => {
          return (_openBlock(), _createBlock(_component_el_tab_pane, {
            key: index,
            label: item.label,
            name: item.name,
            value: item.value
          }, null, 8, ["label", "name", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "onTabClick"])
  ], 2))
}