import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    ref: "text",
    class: _normalizeClass($props.lines > 1  ? 'multi':'single'),
    style: _normalizeStyle({'width': `${$props.width}px`, '-webkit-line-clamp': $props.lines})
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 6))
}