
import { defineComponent, SetupContext, ref, getCurrentInstance, watch } from "vue";
import { conversionTimeFormat } from "@ldos/shared-common/src/util/utilsHandle";
export default defineComponent({
  name: "MasAudio",
  props: {
    source: {
      // 音频来源
      type: String,
      default: "",
      required: true,
    },
    duration: {
      // 音频时长
      type: [Number, String],
      default: 0,
      required: true,
    },
    title: {
      // 音频标题
      type: String,
      default: "",
      required: false,
    },
  },
  setup(props, ctx: SetupContext) {
    const instance: any = getCurrentInstance()?.proxy;

    let initAudio: any = ref({
      play: false, //播放还是暂停 true播放中
      audioLength: 0, //audio时长
      url: "", //音频课件url
      currentTime: 0, //当前播放时间
      lastTime: null, //标记时间戳
      playbackRate: 1,
    });

    const playbackRateOptions: any[] = [
      { label: "0.5倍速", value: 0.5 },
      { label: "1倍速", value: 1 },
      { label: "2倍速", value: 2 },
      { label: "3倍速", value: 3 },
    ]; // 音频倍速配置

    watch(
      () => initAudio,
      (state, prevState) => {
        playbackRateChange();
      },
      { deep: true }
    );

    const playbackRateChange = (): void => {
      // 音频倍速
      instance.$refs.audio.playbackRate = initAudio.value.playbackRate;
    };
    const formatTooltip = (val: number): string => {
      return conversionTimeFormat(val);
    };

    const formatSecond = (second = 0): string => {
      return conversionTimeFormat(second);
    };

    const playAudio = (): void => {
      // 播放视频
      if (initAudio.value.play) {
        instance.$refs.audio.pause();
      } else {
        changeAudio();
      }
    };

    const audioTimeUpdate = (): void => {
      //更新视频时间。节流，每秒触发一次
      let nowTime = Date.now();
      let gapTime = 1000;
      if (!initAudio.value.lastTime || nowTime - initAudio.value.lastTime > gapTime) {
        if (instance.$refs.audio) {
          let time = instance.$refs.audio.currentTime;
          initAudio.value.currentTime = time;
        }
        initAudio.value.lastTime = nowTime;
      }
    };

    const changeAudioTime = (val: unknown): void => {
      //改变音频时间
      if (instance.$refs.audio) {
        instance.$refs.audio.currentTime = val;
        changeAudio();
      }
    };
    const changeAudio = (): void => {
      // 每次改变进度条不暂停音频
      let audioObj = instance.$refs.audio;
      Math.abs(initAudio.value.currentTime - audioObj.currentTime) > 2
        ? (audioObj.currentTime = initAudio.value.currentTime)
        : "";
      audioObj.play();
      audioObj.muted = false;
    };

    const getAudioLength = (): void => {
      //获取音视频时长
      initAudio.value.audioLength = instance.$refs.audio.duration || 0;
    };

    const handPlay = (type: any, status: any): void => {
      //音视频播放暂停 type:1 音频 2视频 status 1播放 2暂停
      if (type === 1) {
        status === 1 ? (initAudio.value.play = true) : (initAudio.value.play = false);
      }
    };
    const pauseAudio = (): void => {
      // 关闭弹层之后 直接暂停播放
      instance.$refs.audio.pause();
    };

    return {
      initAudio,
      playbackRateOptions,
      getAudioLength,
      playbackRateChange,
      changeAudioTime,
      changeAudio,
      audioTimeUpdate,
      formatTooltip,
      formatSecond,
      playAudio,
      handPlay,
      pauseAudio,
    };
  },
});
