import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!

  return (_openBlock(), _createBlock(_component_el_date_picker, {
    "model-value": _ctx.value,
    "onUpdate:modelValue": _ctx.change,
    type: "daterange",
    "range-separator": "-",
    "start-placeholder": "开始时间",
    "end-placeholder": "结束时间"
  }, null, 8, ["model-value", "onUpdate:modelValue"]))
}