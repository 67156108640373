export default [
  {
    key: 'rm',
    title: '风险预警',
    order: 100,
    icon: 'shezhi'
  },
  {
    key: 'home',
    title: '驾驶舱',
    order: 200,
    icon: 'shezhi'
  },
  {
    key: 'report',
    title: '数据报表',
    order: 300,
    icon: 'shezhi'
  },
  {
    key: 'dataProcessing',
    title: '数据处理',
    order: 400,
    icon: 'shezhi'
  },
  {
    key: 'configuration',
    title: '系统设置',
    order: 99999,
    icon: 'shezhi'
  },
]