import { buildEnvironment, EnvironmentInfo } from "@velites/init";
import decorateEnv from "envDecorator";

const environment = buildEnvironment((env: EnvironmentInfo) => {
  env.build.part = "mgr";
  env.settings.wsSettings.normal = {
    relPath: "ws/normal",
    topicPrefix: "ws.normal."
  };
  env.variables.suiteIds = {
    imkt: "dk20b926db1c33de51"
  };
  env.variables.chatBoxSettings = {
    chatCacheSize: 600,
    requestChatCount: 200,
    loadChatCount: 200
  }
  decorateEnv(env);
});

export default environment;
