
import CommonStore from "@/store/modules/common";
import { verification } from "@/utils/verification";
import { Options, Vue } from "vue-class-component";
interface ILoginParams {
  tenantCode: string;
  userName: string;
  password: string;
  loadingText: string;
}
@Options({
  components: {},
  props: {
    title: {
      type: String,
      default: "",
      require: false
    }
  },
  watch: {
    showPassword(nVal) {
      if (nVal) {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    }
  }
})
export default class extends Vue {
  title!: string;
  loginParams: ILoginParams = {
    tenantCode: "", // 经销商
    userName: "", // 用户名
    password: "", // 密码
    loadingText: "登录中"
  };
  errorText = ""; // 错误提示的展示文本
  noLogin = true; // 是否免登陆
  showPassword = false; // 密码是否可见
  passwordType = "password"; // 密码类型

  created() {
    if (localStorage.getItem("LDOS_ACCOUNT_INFO")) {
      this.setLoginInfo(localStorage.getItem("LDOS_ACCOUNT_INFO"));
    }
  }

  setLoginInfo(info: string | null) {
    if (info) {
      const obj = JSON.parse(info);
      const { tenantCode, userName } = obj;
      this.loginParams.tenantCode = tenantCode;
      this.loginParams.userName = userName;
    }
  }

  checkPassword(str: string) {
    return verification.haveSpecialStr.test(str);
  }

  async doLogin(): Promise<boolean | void> {
    let errorArr = [];
    if (!this.loginParams.userName) errorArr.push("账号");
    if (!this.loginParams.password) errorArr.push("密码");
    if (errorArr.length > 0) {
      this.errorText = `请输入${errorArr.join(",")}`;
      return false;
    }
    if (!this.checkPassword(this.loginParams.password)) {
      this.errorText = "密码长度6-16位，仅支持字母、数字、符号（@、%、&、#）组合设置，字母区分大小写，不允许空格"
      return false;
    }
    this.loginParams.tenantCode = 'ARCFOX';
    const [data] = await this.$api.post("/account/login", this.loginParams);
    this.$stores.retrieveStore(CommonStore).SET_NEED_CHANGE_PASSWORD(data.needChangePassword);
    this.$emit("loginEvent");
  }
}
