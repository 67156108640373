import { Pocket, PostMapping, RequestMapping, RequestParam, RestController } from "@/common/decorators";
import { SearchResult } from "@ldos/shared-common/src";
import { SubsidiaryVO, SubsidiarySearchVO, SubsidiaryStatusEnum } from "../vo";

const faker = require('faker');
import { v4 as uuidv4 } from 'uuid';
import { generateRandomArray } from "@ldos/shared-common/src/util/mock";
import moment from "moment";

const administrativeCodes = {
  '110000': '北京市',
  '120000': '天津市',
  '130000': '河北省',
  '140000': '山西省',
  '150000': '内蒙古自治区',
  '210000': '辽宁省',
  '220000': '吉林省',
  '230000': '黑龙江省',
  '310000': '上海市',
  '320000': '江苏省',
  '330000': '浙江省',
  '340000': '安徽省',
  '350000': '福建省',
  '360000': '江西省',
  '370000': '山东省',
  '410000': '河南省',
  '420000': '湖北省',
  '430000': '湖南省',
  '440000': '广东省',
  '450000': '广西壮族自治区',
  '460000': '海南省',
  '500000': '重庆市',
  '510000': '四川省',
  '520000': '贵州省',
  '530000': '云南省',
  '540000': '西藏自治区',
  '610000': '陕西省',
  '620000': '甘肃省',
  '630000': '青海省',
  '640000': '宁夏回族自治区',
  '650000': '新疆维吾尔自治区',
  '710000': '台湾省',
  '810000': '香港特别行政区',
  '820000': '澳门特别行政区'
}

const region = [
  {
    "label": "山东大区部",
    "value": "1949f913-e1e7-11ee-b5aa-5c60ba7073cf"
  },
  {
    "label": "东北大区部",
    "value": "7a03c16f-ea83-11ee-b8b6-5c60ba7073cf"
  },
  {
    "label": "上海大区部",
    "value": "7a088743-ea83-11ee-b8b6-5c60ba7073cf"
  },
  {
    "label": "川藏大区部",
    "value": "7a0cc0cd-ea83-11ee-b8b6-5c60ba7073cf"
  },
  {
    "label": "赣闽大区部",
    "value": "7a10c1df-ea83-11ee-b8b6-5c60ba7073cf"
  },
  {
    "label": "津冀大区部",
    "value": "7a15259f-ea83-11ee-b8b6-5c60ba7073cf"
  },
  {
    "label": "浙南大区部",
    "value": "7a1b5cb5-ea83-11ee-b8b6-5c60ba7073cf"
  },
  {
    "label": "浙皖大区部",
    "value": "7a202686-ea83-11ee-b8b6-5c60ba7073cf"
  },
  {
    "label": "苏北大区部",
    "value": "7a251cb4-ea83-11ee-b8b6-5c60ba7073cf"
  },
  {
    "label": "湘贵大区部",
    "value": "7a2a3cfb-ea83-11ee-b8b6-5c60ba7073cf"
  },
  {
    "label": "陕晋大区部",
    "value": "7a2eca8c-ea83-11ee-b8b6-5c60ba7073cf"
  },
  {
    "label": "重庆大区部",
    "value": "7a381916-ea83-11ee-b8b6-5c60ba7073cf"
  },
  {
    "label": "西北大区部",
    "value": "7a3ba15e-ea83-11ee-b8b6-5c60ba7073cf"
  },
  {
    "label": "云桂大区部",
    "value": "7a408675-ea83-11ee-b8b6-5c60ba7073cf"
  },
  {
    "label": "湖北大区部",
    "value": "7a452ad1-ea83-11ee-b8b6-5c60ba7073cf"
  },
  {
    "label": "北京大区部",
    "value": "7a497a52-ea83-11ee-b8b6-5c60ba7073cf"
  },
  {
    "label": "河南大区部",
    "value": "7a4f8ad4-ea83-11ee-b8b6-5c60ba7073cf"
  }
]

const city = [
  {
    "label": "北京市",
    "value": "110100",
    "extra": {
      "provinceKey": "110000"
    }
  },
  {
    "label": "天津市",
    "value": "120100",
    "extra": {
      "provinceKey": "120000"
    }
  },
  {
    "label": "石家庄市",
    "value": "130100",
    "extra": {
      "provinceKey": "130000"
    }
  },
  {
    "label": "唐山市",
    "value": "130200",
    "extra": {
      "provinceKey": "130000"
    }
  },
  {
    "label": "秦皇岛市",
    "value": "130300",
    "extra": {
      "provinceKey": "130000"
    }
  },
  {
    "label": "邯郸市",
    "value": "130400",
    "extra": {
      "provinceKey": "130000"
    }
  },
  {
    "label": "邢台市",
    "value": "130500",
    "extra": {
      "provinceKey": "130000"
    }
  },
  {
    "label": "保定市",
    "value": "130600",
    "extra": {
      "provinceKey": "130000"
    }
  },
  {
    "label": "张家口市",
    "value": "130700",
    "extra": {
      "provinceKey": "130000"
    }
  },
  {
    "label": "承德市",
    "value": "130800",
    "extra": {
      "provinceKey": "130000"
    }
  },
  {
    "label": "沧州市",
    "value": "130900",
    "extra": {
      "provinceKey": "130000"
    }
  },
  {
    "label": "廊坊市",
    "value": "131000",
    "extra": {
      "provinceKey": "130000"
    }
  },
  {
    "label": "衡水市",
    "value": "131100",
    "extra": {
      "provinceKey": "130000"
    }
  },
  {
    "label": "太原市",
    "value": "140100",
    "extra": {
      "provinceKey": "140000"
    }
  },
  {
    "label": "大同市",
    "value": "140200",
    "extra": {
      "provinceKey": "140000"
    }
  },
  {
    "label": "阳泉市",
    "value": "140300",
    "extra": {
      "provinceKey": "140000"
    }
  },
  {
    "label": "长治市",
    "value": "140400",
    "extra": {
      "provinceKey": "140000"
    }
  },
  {
    "label": "晋城市",
    "value": "140500",
    "extra": {
      "provinceKey": "140000"
    }
  },
  {
    "label": "朔州市",
    "value": "140600",
    "extra": {
      "provinceKey": "140000"
    }
  },
  {
    "label": "晋中市",
    "value": "140700",
    "extra": {
      "provinceKey": "140000"
    }
  },
  {
    "label": "运城市",
    "value": "140800",
    "extra": {
      "provinceKey": "140000"
    }
  },
  {
    "label": "忻州市",
    "value": "140900",
    "extra": {
      "provinceKey": "140000"
    }
  },
  {
    "label": "临汾市",
    "value": "141000",
    "extra": {
      "provinceKey": "140000"
    }
  },
  {
    "label": "吕梁市",
    "value": "141100",
    "extra": {
      "provinceKey": "140000"
    }
  },
  {
    "label": "呼和浩特市",
    "value": "150100",
    "extra": {
      "provinceKey": "150000"
    }
  },
  {
    "label": "包头市",
    "value": "150200",
    "extra": {
      "provinceKey": "150000"
    }
  },
  {
    "label": "乌海市",
    "value": "150300",
    "extra": {
      "provinceKey": "150000"
    }
  },
  {
    "label": "赤峰市",
    "value": "150400",
    "extra": {
      "provinceKey": "150000"
    }
  },
  {
    "label": "通辽市",
    "value": "150500",
    "extra": {
      "provinceKey": "150000"
    }
  },
  {
    "label": "鄂尔多斯市",
    "value": "150600",
    "extra": {
      "provinceKey": "150000"
    }
  },
  {
    "label": "呼伦贝尔市",
    "value": "150700",
    "extra": {
      "provinceKey": "150000"
    }
  },
  {
    "label": "巴彦淖尔市",
    "value": "150800",
    "extra": {
      "provinceKey": "150000"
    }
  },
  {
    "label": "乌兰察布市",
    "value": "150900",
    "extra": {
      "provinceKey": "150000"
    }
  },
  {
    "label": "兴安盟",
    "value": "152200",
    "extra": {
      "provinceKey": "150000"
    }
  },
  {
    "label": "锡林郭勒盟",
    "value": "152500",
    "extra": {
      "provinceKey": "150000"
    }
  },
  {
    "label": "阿拉善盟",
    "value": "152900",
    "extra": {
      "provinceKey": "150000"
    }
  },
  {
    "label": "沈阳市",
    "value": "210100",
    "extra": {
      "provinceKey": "210000"
    }
  },
  {
    "label": "大连市",
    "value": "210200",
    "extra": {
      "provinceKey": "210000"
    }
  },
  {
    "label": "鞍山市",
    "value": "210300",
    "extra": {
      "provinceKey": "210000"
    }
  },
  {
    "label": "抚顺市",
    "value": "210400",
    "extra": {
      "provinceKey": "210000"
    }
  },
  {
    "label": "本溪市",
    "value": "210500",
    "extra": {
      "provinceKey": "210000"
    }
  },
  {
    "label": "丹东市",
    "value": "210600",
    "extra": {
      "provinceKey": "210000"
    }
  },
  {
    "label": "锦州市",
    "value": "210700",
    "extra": {
      "provinceKey": "210000"
    }
  },
  {
    "label": "营口市",
    "value": "210800",
    "extra": {
      "provinceKey": "210000"
    }
  },
  {
    "label": "阜新市",
    "value": "210900",
    "extra": {
      "provinceKey": "210000"
    }
  },
  {
    "label": "辽阳市",
    "value": "211000",
    "extra": {
      "provinceKey": "210000"
    }
  },
  {
    "label": "盘锦市",
    "value": "211100",
    "extra": {
      "provinceKey": "210000"
    }
  },
  {
    "label": "铁岭市",
    "value": "211200",
    "extra": {
      "provinceKey": "210000"
    }
  },
  {
    "label": "朝阳市",
    "value": "211300",
    "extra": {
      "provinceKey": "210000"
    }
  },
  {
    "label": "葫芦岛市",
    "value": "211400",
    "extra": {
      "provinceKey": "210000"
    }
  },
  {
    "label": "长春市",
    "value": "220100",
    "extra": {
      "provinceKey": "220000"
    }
  },
  {
    "label": "吉林市",
    "value": "220200",
    "extra": {
      "provinceKey": "220000"
    }
  },
  {
    "label": "四平市",
    "value": "220300",
    "extra": {
      "provinceKey": "220000"
    }
  },
  {
    "label": "辽源市",
    "value": "220400",
    "extra": {
      "provinceKey": "220000"
    }
  },
  {
    "label": "通化市",
    "value": "220500",
    "extra": {
      "provinceKey": "220000"
    }
  },
  {
    "label": "白山市",
    "value": "220600",
    "extra": {
      "provinceKey": "220000"
    }
  },
  {
    "label": "松原市",
    "value": "220700",
    "extra": {
      "provinceKey": "220000"
    }
  },
  {
    "label": "白城市",
    "value": "220800",
    "extra": {
      "provinceKey": "220000"
    }
  },
  {
    "label": "延边朝鲜族自治州",
    "value": "222400",
    "extra": {
      "provinceKey": "220000"
    }
  },
  {
    "label": "哈尔滨市",
    "value": "230100",
    "extra": {
      "provinceKey": "230000"
    }
  },
  {
    "label": "齐齐哈尔市",
    "value": "230200",
    "extra": {
      "provinceKey": "230000"
    }
  },
  {
    "label": "鸡西市",
    "value": "230300",
    "extra": {
      "provinceKey": "230000"
    }
  },
  {
    "label": "鹤岗市",
    "value": "230400",
    "extra": {
      "provinceKey": "230000"
    }
  },
  {
    "label": "双鸭山市",
    "value": "230500",
    "extra": {
      "provinceKey": "230000"
    }
  },
  {
    "label": "大庆市",
    "value": "230600",
    "extra": {
      "provinceKey": "230000"
    }
  },
  {
    "label": "伊春市",
    "value": "230700",
    "extra": {
      "provinceKey": "230000"
    }
  },
  {
    "label": "佳木斯市",
    "value": "230800",
    "extra": {
      "provinceKey": "230000"
    }
  },
  {
    "label": "七台河市",
    "value": "230900",
    "extra": {
      "provinceKey": "230000"
    }
  },
  {
    "label": "牡丹江市",
    "value": "231000",
    "extra": {
      "provinceKey": "230000"
    }
  },
  {
    "label": "黑河市",
    "value": "231100",
    "extra": {
      "provinceKey": "230000"
    }
  },
  {
    "label": "绥化市",
    "value": "231200",
    "extra": {
      "provinceKey": "230000"
    }
  },
  {
    "label": "大兴安岭地区",
    "value": "232700",
    "extra": {
      "provinceKey": "230000"
    }
  },
  {
    "label": "上海市",
    "value": "310100",
    "extra": {
      "provinceKey": "310000"
    }
  },
  {
    "label": "南京市",
    "value": "320100",
    "extra": {
      "provinceKey": "320000"
    }
  },
  {
    "label": "无锡市",
    "value": "320200",
    "extra": {
      "provinceKey": "320000"
    }
  },
  {
    "label": "徐州市",
    "value": "320300",
    "extra": {
      "provinceKey": "320000"
    }
  },
  {
    "label": "常州市",
    "value": "320400",
    "extra": {
      "provinceKey": "320000"
    }
  },
  {
    "label": "苏州市",
    "value": "320500",
    "extra": {
      "provinceKey": "320000"
    }
  },
  {
    "label": "南通市",
    "value": "320600",
    "extra": {
      "provinceKey": "320000"
    }
  },
  {
    "label": "连云港市",
    "value": "320700",
    "extra": {
      "provinceKey": "320000"
    }
  },
  {
    "label": "淮安市",
    "value": "320800",
    "extra": {
      "provinceKey": "320000"
    }
  },
  {
    "label": "盐城市",
    "value": "320900",
    "extra": {
      "provinceKey": "320000"
    }
  },
  {
    "label": "扬州市",
    "value": "321000",
    "extra": {
      "provinceKey": "320000"
    }
  },
  {
    "label": "镇江市",
    "value": "321100",
    "extra": {
      "provinceKey": "320000"
    }
  },
  {
    "label": "泰州市",
    "value": "321200",
    "extra": {
      "provinceKey": "320000"
    }
  },
  {
    "label": "宿迁市",
    "value": "321300",
    "extra": {
      "provinceKey": "320000"
    }
  },
  {
    "label": "杭州市",
    "value": "330100",
    "extra": {
      "provinceKey": "330000"
    }
  },
  {
    "label": "宁波市",
    "value": "330200",
    "extra": {
      "provinceKey": "330000"
    }
  },
  {
    "label": "温州市",
    "value": "330300",
    "extra": {
      "provinceKey": "330000"
    }
  },
  {
    "label": "嘉兴市",
    "value": "330400",
    "extra": {
      "provinceKey": "330000"
    }
  },
  {
    "label": "湖州市",
    "value": "330500",
    "extra": {
      "provinceKey": "330000"
    }
  },
  {
    "label": "绍兴市",
    "value": "330600",
    "extra": {
      "provinceKey": "330000"
    }
  },
  {
    "label": "金华市",
    "value": "330700",
    "extra": {
      "provinceKey": "330000"
    }
  },
  {
    "label": "衢州市",
    "value": "330800",
    "extra": {
      "provinceKey": "330000"
    }
  },
  {
    "label": "舟山市",
    "value": "330900",
    "extra": {
      "provinceKey": "330000"
    }
  },
  {
    "label": "台州市",
    "value": "331000",
    "extra": {
      "provinceKey": "330000"
    }
  },
  {
    "label": "丽水市",
    "value": "331100",
    "extra": {
      "provinceKey": "330000"
    }
  },
  {
    "label": "合肥市",
    "value": "340100",
    "extra": {
      "provinceKey": "340000"
    }
  },
  {
    "label": "芜湖市",
    "value": "340200",
    "extra": {
      "provinceKey": "340000"
    }
  },
  {
    "label": "蚌埠市",
    "value": "340300",
    "extra": {
      "provinceKey": "340000"
    }
  },
  {
    "label": "淮南市",
    "value": "340400",
    "extra": {
      "provinceKey": "340000"
    }
  },
  {
    "label": "马鞍山市",
    "value": "340500",
    "extra": {
      "provinceKey": "340000"
    }
  },
  {
    "label": "淮北市",
    "value": "340600",
    "extra": {
      "provinceKey": "340000"
    }
  },
  {
    "label": "铜陵市",
    "value": "340700",
    "extra": {
      "provinceKey": "340000"
    }
  },
  {
    "label": "安庆市",
    "value": "340800",
    "extra": {
      "provinceKey": "340000"
    }
  },
  {
    "label": "黄山市",
    "value": "341000",
    "extra": {
      "provinceKey": "340000"
    }
  },
  {
    "label": "滁州市",
    "value": "341100",
    "extra": {
      "provinceKey": "340000"
    }
  },
  {
    "label": "阜阳市",
    "value": "341200",
    "extra": {
      "provinceKey": "340000"
    }
  },
  {
    "label": "宿州市",
    "value": "341300",
    "extra": {
      "provinceKey": "340000"
    }
  },
  {
    "label": "六安市",
    "value": "341500",
    "extra": {
      "provinceKey": "340000"
    }
  },
  {
    "label": "亳州市",
    "value": "341600",
    "extra": {
      "provinceKey": "340000"
    }
  },
  {
    "label": "池州市",
    "value": "341700",
    "extra": {
      "provinceKey": "340000"
    }
  },
  {
    "label": "宣城市",
    "value": "341800",
    "extra": {
      "provinceKey": "340000"
    }
  },
  {
    "label": "福州市",
    "value": "350100",
    "extra": {
      "provinceKey": "350000"
    }
  },
  {
    "label": "厦门市",
    "value": "350200",
    "extra": {
      "provinceKey": "350000"
    }
  },
  {
    "label": "莆田市",
    "value": "350300",
    "extra": {
      "provinceKey": "350000"
    }
  },
  {
    "label": "三明市",
    "value": "350400",
    "extra": {
      "provinceKey": "350000"
    }
  },
  {
    "label": "泉州市",
    "value": "350500",
    "extra": {
      "provinceKey": "350000"
    }
  },
  {
    "label": "漳州市",
    "value": "350600",
    "extra": {
      "provinceKey": "350000"
    }
  },
  {
    "label": "南平市",
    "value": "350700",
    "extra": {
      "provinceKey": "350000"
    }
  },
  {
    "label": "龙岩市",
    "value": "350800",
    "extra": {
      "provinceKey": "350000"
    }
  },
  {
    "label": "宁德市",
    "value": "350900",
    "extra": {
      "provinceKey": "350000"
    }
  },
  {
    "label": "南昌市",
    "value": "360100",
    "extra": {
      "provinceKey": "360000"
    }
  },
  {
    "label": "景德镇市",
    "value": "360200",
    "extra": {
      "provinceKey": "360000"
    }
  },
  {
    "label": "萍乡市",
    "value": "360300",
    "extra": {
      "provinceKey": "360000"
    }
  },
  {
    "label": "九江市",
    "value": "360400",
    "extra": {
      "provinceKey": "360000"
    }
  },
  {
    "label": "新余市",
    "value": "360500",
    "extra": {
      "provinceKey": "360000"
    }
  },
  {
    "label": "鹰潭市",
    "value": "360600",
    "extra": {
      "provinceKey": "360000"
    }
  },
  {
    "label": "赣州市",
    "value": "360700",
    "extra": {
      "provinceKey": "360000"
    }
  },
  {
    "label": "吉安市",
    "value": "360800",
    "extra": {
      "provinceKey": "360000"
    }
  },
  {
    "label": "宜春市",
    "value": "360900",
    "extra": {
      "provinceKey": "360000"
    }
  },
  {
    "label": "抚州市",
    "value": "361000",
    "extra": {
      "provinceKey": "360000"
    }
  },
  {
    "label": "上饶市",
    "value": "361100",
    "extra": {
      "provinceKey": "360000"
    }
  },
  {
    "label": "济南市",
    "value": "370100",
    "extra": {
      "provinceKey": "370000"
    }
  },
  {
    "label": "青岛市",
    "value": "370200",
    "extra": {
      "provinceKey": "370000"
    }
  },
  {
    "label": "淄博市",
    "value": "370300",
    "extra": {
      "provinceKey": "370000"
    }
  },
  {
    "label": "枣庄市",
    "value": "370400",
    "extra": {
      "provinceKey": "370000"
    }
  },
  {
    "label": "东营市",
    "value": "370500",
    "extra": {
      "provinceKey": "370000"
    }
  },
  {
    "label": "烟台市",
    "value": "370600",
    "extra": {
      "provinceKey": "370000"
    }
  },
  {
    "label": "潍坊市",
    "value": "370700",
    "extra": {
      "provinceKey": "370000"
    }
  },
  {
    "label": "济宁市",
    "value": "370800",
    "extra": {
      "provinceKey": "370000"
    }
  },
  {
    "label": "泰安市",
    "value": "370900",
    "extra": {
      "provinceKey": "370000"
    }
  },
  {
    "label": "威海市",
    "value": "371000",
    "extra": {
      "provinceKey": "370000"
    }
  },
  {
    "label": "日照市",
    "value": "371100",
    "extra": {
      "provinceKey": "370000"
    }
  },
  {
    "label": "临沂市",
    "value": "371300",
    "extra": {
      "provinceKey": "370000"
    }
  },
  {
    "label": "德州市",
    "value": "371400",
    "extra": {
      "provinceKey": "370000"
    }
  },
  {
    "label": "聊城市",
    "value": "371500",
    "extra": {
      "provinceKey": "370000"
    }
  },
  {
    "label": "滨州市",
    "value": "371600",
    "extra": {
      "provinceKey": "370000"
    }
  },
  {
    "label": "菏泽市",
    "value": "371700",
    "extra": {
      "provinceKey": "370000"
    }
  },
  {
    "label": "郑州市",
    "value": "410100",
    "extra": {
      "provinceKey": "410000"
    }
  },
  {
    "label": "开封市",
    "value": "410200",
    "extra": {
      "provinceKey": "410000"
    }
  },
  {
    "label": "洛阳市",
    "value": "410300",
    "extra": {
      "provinceKey": "410000"
    }
  },
  {
    "label": "平顶山市",
    "value": "410400",
    "extra": {
      "provinceKey": "410000"
    }
  },
  {
    "label": "安阳市",
    "value": "410500",
    "extra": {
      "provinceKey": "410000"
    }
  },
  {
    "label": "鹤壁市",
    "value": "410600",
    "extra": {
      "provinceKey": "410000"
    }
  },
  {
    "label": "新乡市",
    "value": "410700",
    "extra": {
      "provinceKey": "410000"
    }
  },
  {
    "label": "焦作市",
    "value": "410800",
    "extra": {
      "provinceKey": "410000"
    }
  },
  {
    "label": "濮阳市",
    "value": "410900",
    "extra": {
      "provinceKey": "410000"
    }
  },
  {
    "label": "许昌市",
    "value": "411000",
    "extra": {
      "provinceKey": "410000"
    }
  },
  {
    "label": "漯河市",
    "value": "411100",
    "extra": {
      "provinceKey": "410000"
    }
  },
  {
    "label": "三门峡市",
    "value": "411200",
    "extra": {
      "provinceKey": "410000"
    }
  },
  {
    "label": "南阳市",
    "value": "411300",
    "extra": {
      "provinceKey": "410000"
    }
  },
  {
    "label": "商丘市",
    "value": "411400",
    "extra": {
      "provinceKey": "410000"
    }
  },
  {
    "label": "信阳市",
    "value": "411500",
    "extra": {
      "provinceKey": "410000"
    }
  },
  {
    "label": "周口市",
    "value": "411600",
    "extra": {
      "provinceKey": "410000"
    }
  },
  {
    "label": "驻马店市",
    "value": "411700",
    "extra": {
      "provinceKey": "410000"
    }
  },
  {
    "label": "武汉市",
    "value": "420100",
    "extra": {
      "provinceKey": "420000"
    }
  },
  {
    "label": "黄石市",
    "value": "420200",
    "extra": {
      "provinceKey": "420000"
    }
  },
  {
    "label": "十堰市",
    "value": "420300",
    "extra": {
      "provinceKey": "420000"
    }
  },
  {
    "label": "宜昌市",
    "value": "420500",
    "extra": {
      "provinceKey": "420000"
    }
  },
  {
    "label": "襄阳市",
    "value": "420600",
    "extra": {
      "provinceKey": "420000"
    }
  },
  {
    "label": "鄂州市",
    "value": "420700",
    "extra": {
      "provinceKey": "420000"
    }
  },
  {
    "label": "荆门市",
    "value": "420800",
    "extra": {
      "provinceKey": "420000"
    }
  },
  {
    "label": "孝感市",
    "value": "420900",
    "extra": {
      "provinceKey": "420000"
    }
  },
  {
    "label": "荆州市",
    "value": "421000",
    "extra": {
      "provinceKey": "420000"
    }
  },
  {
    "label": "黄冈市",
    "value": "421100",
    "extra": {
      "provinceKey": "420000"
    }
  },
  {
    "label": "咸宁市",
    "value": "421200",
    "extra": {
      "provinceKey": "420000"
    }
  },
  {
    "label": "随州市",
    "value": "421300",
    "extra": {
      "provinceKey": "420000"
    }
  },
  {
    "label": "恩施土家族苗族自治州",
    "value": "422800",
    "extra": {
      "provinceKey": "420000"
    }
  },
  {
    "label": "长沙市",
    "value": "430100",
    "extra": {
      "provinceKey": "430000"
    }
  },
  {
    "label": "株洲市",
    "value": "430200",
    "extra": {
      "provinceKey": "430000"
    }
  },
  {
    "label": "湘潭市",
    "value": "430300",
    "extra": {
      "provinceKey": "430000"
    }
  },
  {
    "label": "衡阳市",
    "value": "430400",
    "extra": {
      "provinceKey": "430000"
    }
  },
  {
    "label": "邵阳市",
    "value": "430500",
    "extra": {
      "provinceKey": "430000"
    }
  },
  {
    "label": "岳阳市",
    "value": "430600",
    "extra": {
      "provinceKey": "430000"
    }
  },
  {
    "label": "常德市",
    "value": "430700",
    "extra": {
      "provinceKey": "430000"
    }
  },
  {
    "label": "张家界市",
    "value": "430800",
    "extra": {
      "provinceKey": "430000"
    }
  },
  {
    "label": "益阳市",
    "value": "430900",
    "extra": {
      "provinceKey": "430000"
    }
  },
  {
    "label": "郴州市",
    "value": "431000",
    "extra": {
      "provinceKey": "430000"
    }
  },
  {
    "label": "永州市",
    "value": "431100",
    "extra": {
      "provinceKey": "430000"
    }
  },
  {
    "label": "怀化市",
    "value": "431200",
    "extra": {
      "provinceKey": "430000"
    }
  },
  {
    "label": "娄底市",
    "value": "431300",
    "extra": {
      "provinceKey": "430000"
    }
  },
  {
    "label": "湘西土家族苗族自治州",
    "value": "433100",
    "extra": {
      "provinceKey": "430000"
    }
  },
  {
    "label": "广州市",
    "value": "440100",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "韶关市",
    "value": "440200",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "深圳市",
    "value": "440300",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "珠海市",
    "value": "440400",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "汕头市",
    "value": "440500",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "佛山市",
    "value": "440600",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "江门市",
    "value": "440700",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "湛江市",
    "value": "440800",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "茂名市",
    "value": "440900",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "肇庆市",
    "value": "441200",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "惠州市",
    "value": "441300",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "梅州市",
    "value": "441400",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "汕尾市",
    "value": "441500",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "河源市",
    "value": "441600",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "阳江市",
    "value": "441700",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "清远市",
    "value": "441800",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "东莞市",
    "value": "441900",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "中山市",
    "value": "442000",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "潮州市",
    "value": "445100",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "揭阳市",
    "value": "445200",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "云浮市",
    "value": "445300",
    "extra": {
      "provinceKey": "440000"
    }
  },
  {
    "label": "南宁市",
    "value": "450100",
    "extra": {
      "provinceKey": "450000"
    }
  },
  {
    "label": "柳州市",
    "value": "450200",
    "extra": {
      "provinceKey": "450000"
    }
  },
  {
    "label": "桂林市",
    "value": "450300",
    "extra": {
      "provinceKey": "450000"
    }
  },
  {
    "label": "梧州市",
    "value": "450400",
    "extra": {
      "provinceKey": "450000"
    }
  },
  {
    "label": "北海市",
    "value": "450500",
    "extra": {
      "provinceKey": "450000"
    }
  },
  {
    "label": "防城港市",
    "value": "450600",
    "extra": {
      "provinceKey": "450000"
    }
  },
  {
    "label": "钦州市",
    "value": "450700",
    "extra": {
      "provinceKey": "450000"
    }
  },
  {
    "label": "贵港市",
    "value": "450800",
    "extra": {
      "provinceKey": "450000"
    }
  },
  {
    "label": "玉林市",
    "value": "450900",
    "extra": {
      "provinceKey": "450000"
    }
  },
  {
    "label": "百色市",
    "value": "451000",
    "extra": {
      "provinceKey": "450000"
    }
  },
  {
    "label": "贺州市",
    "value": "451100",
    "extra": {
      "provinceKey": "450000"
    }
  },
  {
    "label": "河池市",
    "value": "451200",
    "extra": {
      "provinceKey": "450000"
    }
  },
  {
    "label": "来宾市",
    "value": "451300",
    "extra": {
      "provinceKey": "450000"
    }
  },
  {
    "label": "崇左市",
    "value": "451400",
    "extra": {
      "provinceKey": "450000"
    }
  },
  {
    "label": "海口市",
    "value": "460100",
    "extra": {
      "provinceKey": "460000"
    }
  },
  {
    "label": "三亚市",
    "value": "460200",
    "extra": {
      "provinceKey": "460000"
    }
  },
  {
    "label": "三沙市",
    "value": "460300",
    "extra": {
      "provinceKey": "460000"
    }
  },
  {
    "label": "儋州市",
    "value": "460400",
    "extra": {
      "provinceKey": "460000"
    }
  },
  {
    "label": "重庆市",
    "value": "500100",
    "extra": {
      "provinceKey": "500000"
    }
  },
  {
    "label": "成都市",
    "value": "510100",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "自贡市",
    "value": "510300",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "攀枝花市",
    "value": "510400",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "泸州市",
    "value": "510500",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "德阳市",
    "value": "510600",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "绵阳市",
    "value": "510700",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "广元市",
    "value": "510800",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "遂宁市",
    "value": "510900",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "内江市",
    "value": "511000",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "乐山市",
    "value": "511100",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "南充市",
    "value": "511300",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "眉山市",
    "value": "511400",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "宜宾市",
    "value": "511500",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "广安市",
    "value": "511600",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "达州市",
    "value": "511700",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "雅安市",
    "value": "511800",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "巴中市",
    "value": "511900",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "资阳市",
    "value": "512000",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "阿坝藏族羌族自治州",
    "value": "513200",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "甘孜藏族自治州",
    "value": "513300",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "凉山彝族自治州",
    "value": "513400",
    "extra": {
      "provinceKey": "510000"
    }
  },
  {
    "label": "贵阳市",
    "value": "520100",
    "extra": {
      "provinceKey": "520000"
    }
  },
  {
    "label": "六盘水市",
    "value": "520200",
    "extra": {
      "provinceKey": "520000"
    }
  },
  {
    "label": "遵义市",
    "value": "520300",
    "extra": {
      "provinceKey": "520000"
    }
  },
  {
    "label": "安顺市",
    "value": "520400",
    "extra": {
      "provinceKey": "520000"
    }
  },
  {
    "label": "毕节市",
    "value": "520500",
    "extra": {
      "provinceKey": "520000"
    }
  },
  {
    "label": "铜仁市",
    "value": "520600",
    "extra": {
      "provinceKey": "520000"
    }
  },
  {
    "label": "黔西南布依族苗族自治州",
    "value": "522300",
    "extra": {
      "provinceKey": "520000"
    }
  },
  {
    "label": "黔东南苗族侗族自治州",
    "value": "522600",
    "extra": {
      "provinceKey": "520000"
    }
  },
  {
    "label": "黔南布依族苗族自治州",
    "value": "522700",
    "extra": {
      "provinceKey": "520000"
    }
  },
  {
    "label": "昆明市",
    "value": "530100",
    "extra": {
      "provinceKey": "530000"
    }
  },
  {
    "label": "曲靖市",
    "value": "530300",
    "extra": {
      "provinceKey": "530000"
    }
  },
  {
    "label": "玉溪市",
    "value": "530400",
    "extra": {
      "provinceKey": "530000"
    }
  },
  {
    "label": "保山市",
    "value": "530500",
    "extra": {
      "provinceKey": "530000"
    }
  },
  {
    "label": "昭通市",
    "value": "530600",
    "extra": {
      "provinceKey": "530000"
    }
  },
  {
    "label": "丽江市",
    "value": "530700",
    "extra": {
      "provinceKey": "530000"
    }
  },
  {
    "label": "普洱市",
    "value": "530800",
    "extra": {
      "provinceKey": "530000"
    }
  },
  {
    "label": "临沧市",
    "value": "530900",
    "extra": {
      "provinceKey": "530000"
    }
  },
  {
    "label": "楚雄彝族自治州",
    "value": "532300",
    "extra": {
      "provinceKey": "530000"
    }
  },
  {
    "label": "红河哈尼族彝族自治州",
    "value": "532500",
    "extra": {
      "provinceKey": "530000"
    }
  },
  {
    "label": "文山壮族苗族自治州",
    "value": "532600",
    "extra": {
      "provinceKey": "530000"
    }
  },
  {
    "label": "西双版纳傣族自治州",
    "value": "532800",
    "extra": {
      "provinceKey": "530000"
    }
  },
  {
    "label": "大理白族自治州",
    "value": "532900",
    "extra": {
      "provinceKey": "530000"
    }
  },
  {
    "label": "德宏傣族景颇族自治州",
    "value": "533100",
    "extra": {
      "provinceKey": "530000"
    }
  },
  {
    "label": "怒江傈僳族自治州",
    "value": "533300",
    "extra": {
      "provinceKey": "530000"
    }
  },
  {
    "label": "迪庆藏族自治州",
    "value": "533400",
    "extra": {
      "provinceKey": "530000"
    }
  },
  {
    "label": "拉萨市",
    "value": "540100",
    "extra": {
      "provinceKey": "540000"
    }
  },
  {
    "label": "日喀则市",
    "value": "540200",
    "extra": {
      "provinceKey": "540000"
    }
  },
  {
    "label": "昌都市",
    "value": "540300",
    "extra": {
      "provinceKey": "540000"
    }
  },
  {
    "label": "林芝市",
    "value": "540400",
    "extra": {
      "provinceKey": "540000"
    }
  },
  {
    "label": "山南市",
    "value": "540500",
    "extra": {
      "provinceKey": "540000"
    }
  },
  {
    "label": "那曲市",
    "value": "540600",
    "extra": {
      "provinceKey": "540000"
    }
  },
  {
    "label": "阿里地区",
    "value": "542500",
    "extra": {
      "provinceKey": "540000"
    }
  },
  {
    "label": "西安市",
    "value": "610100",
    "extra": {
      "provinceKey": "610000"
    }
  },
  {
    "label": "铜川市",
    "value": "610200",
    "extra": {
      "provinceKey": "610000"
    }
  },
  {
    "label": "宝鸡市",
    "value": "610300",
    "extra": {
      "provinceKey": "610000"
    }
  },
  {
    "label": "咸阳市",
    "value": "610400",
    "extra": {
      "provinceKey": "610000"
    }
  },
  {
    "label": "渭南市",
    "value": "610500",
    "extra": {
      "provinceKey": "610000"
    }
  },
  {
    "label": "延安市",
    "value": "610600",
    "extra": {
      "provinceKey": "610000"
    }
  },
  {
    "label": "汉中市",
    "value": "610700",
    "extra": {
      "provinceKey": "610000"
    }
  },
  {
    "label": "榆林市",
    "value": "610800",
    "extra": {
      "provinceKey": "610000"
    }
  },
  {
    "label": "安康市",
    "value": "610900",
    "extra": {
      "provinceKey": "610000"
    }
  },
  {
    "label": "商洛市",
    "value": "611000",
    "extra": {
      "provinceKey": "610000"
    }
  },
  {
    "label": "兰州市",
    "value": "620100",
    "extra": {
      "provinceKey": "620000"
    }
  },
  {
    "label": "嘉峪关市",
    "value": "620200",
    "extra": {
      "provinceKey": "620000"
    }
  },
  {
    "label": "金昌市",
    "value": "620300",
    "extra": {
      "provinceKey": "620000"
    }
  },
  {
    "label": "白银市",
    "value": "620400",
    "extra": {
      "provinceKey": "620000"
    }
  },
  {
    "label": "天水市",
    "value": "620500",
    "extra": {
      "provinceKey": "620000"
    }
  },
  {
    "label": "武威市",
    "value": "620600",
    "extra": {
      "provinceKey": "620000"
    }
  },
  {
    "label": "张掖市",
    "value": "620700",
    "extra": {
      "provinceKey": "620000"
    }
  },
  {
    "label": "平凉市",
    "value": "620800",
    "extra": {
      "provinceKey": "620000"
    }
  },
  {
    "label": "酒泉市",
    "value": "620900",
    "extra": {
      "provinceKey": "620000"
    }
  },
  {
    "label": "庆阳市",
    "value": "621000",
    "extra": {
      "provinceKey": "620000"
    }
  },
  {
    "label": "定西市",
    "value": "621100",
    "extra": {
      "provinceKey": "620000"
    }
  },
  {
    "label": "陇南市",
    "value": "621200",
    "extra": {
      "provinceKey": "620000"
    }
  },
  {
    "label": "临夏回族自治州",
    "value": "622900",
    "extra": {
      "provinceKey": "620000"
    }
  },
  {
    "label": "甘南藏族自治州",
    "value": "623000",
    "extra": {
      "provinceKey": "620000"
    }
  },
  {
    "label": "西宁市",
    "value": "630100",
    "extra": {
      "provinceKey": "630000"
    }
  },
  {
    "label": "海东市",
    "value": "630200",
    "extra": {
      "provinceKey": "630000"
    }
  },
  {
    "label": "海北藏族自治州",
    "value": "632200",
    "extra": {
      "provinceKey": "630000"
    }
  },
  {
    "label": "黄南藏族自治州",
    "value": "632300",
    "extra": {
      "provinceKey": "630000"
    }
  },
  {
    "label": "海南藏族自治州",
    "value": "632500",
    "extra": {
      "provinceKey": "630000"
    }
  },
  {
    "label": "果洛藏族自治州",
    "value": "632600",
    "extra": {
      "provinceKey": "630000"
    }
  },
  {
    "label": "玉树藏族自治州",
    "value": "632700",
    "extra": {
      "provinceKey": "630000"
    }
  },
  {
    "label": "海西蒙古族藏族自治州",
    "value": "632800",
    "extra": {
      "provinceKey": "630000"
    }
  },
  {
    "label": "银川市",
    "value": "640100",
    "extra": {
      "provinceKey": "640000"
    }
  },
  {
    "label": "石嘴山市",
    "value": "640200",
    "extra": {
      "provinceKey": "640000"
    }
  },
  {
    "label": "吴忠市",
    "value": "640300",
    "extra": {
      "provinceKey": "640000"
    }
  },
  {
    "label": "固原市",
    "value": "640400",
    "extra": {
      "provinceKey": "640000"
    }
  },
  {
    "label": "中卫市",
    "value": "640500",
    "extra": {
      "provinceKey": "640000"
    }
  },
  {
    "label": "乌鲁木齐市",
    "value": "650100",
    "extra": {
      "provinceKey": "650000"
    }
  },
  {
    "label": "克拉玛依市",
    "value": "650200",
    "extra": {
      "provinceKey": "650000"
    }
  },
  {
    "label": "吐鲁番市",
    "value": "650400",
    "extra": {
      "provinceKey": "650000"
    }
  },
  {
    "label": "哈密市",
    "value": "650500",
    "extra": {
      "provinceKey": "650000"
    }
  },
  {
    "label": "昌吉回族自治州",
    "value": "652300",
    "extra": {
      "provinceKey": "650000"
    }
  },
  {
    "label": "博尔塔拉蒙古自治州",
    "value": "652700",
    "extra": {
      "provinceKey": "650000"
    }
  },
  {
    "label": "巴音郭楞蒙古自治州",
    "value": "652800",
    "extra": {
      "provinceKey": "650000"
    }
  },
  {
    "label": "阿克苏地区",
    "value": "652900",
    "extra": {
      "provinceKey": "650000"
    }
  },
  {
    "label": "克孜勒苏柯尔克孜自治州",
    "value": "653000",
    "extra": {
      "provinceKey": "650000"
    }
  },
  {
    "label": "喀什地区",
    "value": "653100",
    "extra": {
      "provinceKey": "650000"
    }
  },
  {
    "label": "和田地区",
    "value": "653200",
    "extra": {
      "provinceKey": "650000"
    }
  },
  {
    "label": "伊犁哈萨克自治州",
    "value": "654000",
    "extra": {
      "provinceKey": "650000"
    }
  },
  {
    "label": "塔城地区",
    "value": "654200",
    "extra": {
      "provinceKey": "650000"
    }
  },
  {
    "label": "阿勒泰地区",
    "value": "654300",
    "extra": {
      "provinceKey": "650000"
    }
  },
  {
    "label": "台北市",
    "value": "710100",
    "extra": {
      "provinceKey": "710000"
    }
  },
  {
    "label": "高雄市",
    "value": "710200",
    "extra": {
      "provinceKey": "710000"
    }
  },
  {
    "label": "台南市",
    "value": "710300",
    "extra": {
      "provinceKey": "710000"
    }
  },
  {
    "label": "台中市",
    "value": "710400",
    "extra": {
      "provinceKey": "710000"
    }
  },
  {
    "label": "南投县",
    "value": "710600",
    "extra": {
      "provinceKey": "710000"
    }
  },
  {
    "label": "基隆市",
    "value": "710700",
    "extra": {
      "provinceKey": "710000"
    }
  },
  {
    "label": "新竹市",
    "value": "710800",
    "extra": {
      "provinceKey": "710000"
    }
  },
  {
    "label": "嘉义市",
    "value": "710900",
    "extra": {
      "provinceKey": "710000"
    }
  },
  {
    "label": "新北市",
    "value": "711100",
    "extra": {
      "provinceKey": "710000"
    }
  },
  {
    "label": "宜兰县",
    "value": "711200",
    "extra": {
      "provinceKey": "710000"
    }
  },
  {
    "label": "新竹县",
    "value": "711300",
    "extra": {
      "provinceKey": "710000"
    }
  },
  {
    "label": "桃园市",
    "value": "711400",
    "extra": {
      "provinceKey": "710000"
    }
  },
  {
    "label": "苗栗县",
    "value": "711500",
    "extra": {
      "provinceKey": "710000"
    }
  },
  {
    "label": "彰化县",
    "value": "711700",
    "extra": {
      "provinceKey": "710000"
    }
  },
  {
    "label": "嘉义县",
    "value": "711900",
    "extra": {
      "provinceKey": "710000"
    }
  },
  {
    "label": "云林县",
    "value": "712100",
    "extra": {
      "provinceKey": "710000"
    }
  },
  {
    "label": "屏东县",
    "value": "712400",
    "extra": {
      "provinceKey": "710000"
    }
  },
  {
    "label": "台东县",
    "value": "712500",
    "extra": {
      "provinceKey": "710000"
    }
  },
  {
    "label": "花莲县",
    "value": "712600",
    "extra": {
      "provinceKey": "710000"
    }
  },
  {
    "label": "澎湖县",
    "value": "712700",
    "extra": {
      "provinceKey": "710000"
    }
  },
  {
    "label": "香港特别行政区",
    "value": "810100",
    "extra": {
      "provinceKey": "810000"
    }
  },
  {
    "label": "澳门特别行政区",
    "value": "820100",
    "extra": {
      "provinceKey": "820000"
    }
  }
]

export const getSubsidiaryVO = (): SubsidiaryVO => {
  const id = uuidv4();
  const name = faker.address.city();
  const disabled = faker.random.arrayElement(Object.values(SubsidiaryStatusEnum));
  const province = faker.random.arrayElement(Object.keys(administrativeCodes));
  return {
    id,
    name,
    disabled,
    city: faker.random.arrayElement(city.filter((it) => it.extra.provinceKey === province))?.value,
    regionId: faker.random.arrayElement(region)?.value,
    province: province,
    createTime: moment().valueOf(),
    updateTime: moment().valueOf(),
  };
};


@RestController
@RequestMapping('/sys-subsidiary')
export default class SubsidiaryController {

  loadFlag = true;
  async search(req: SubsidiarySearchVO): Promise<SearchResult<SubsidiaryVO>> {
    const reqFn = this.loadFlag ? this.load : this.page;
    this.loadFlag = false;
    return reqFn(req);
  }

  @Pocket(city, 'city')
  @Pocket(region, 'region')
  @Pocket(administrativeCodes, 'province')
  @PostMapping('/load')
  async load(req: SubsidiarySearchVO): Promise<SearchResult<SubsidiaryVO>> {
    return this.page(req);
  }

  @PostMapping('/page')
  async page(req: SubsidiarySearchVO): Promise<SearchResult<SubsidiaryVO>> {
    return {
      totalElements: 23,
      hasMore: true,
      result: generateRandomArray(getSubsidiaryVO),
    };
  }

  async upset(reqBody: SubsidiaryVO): Promise<Boolean> {
    const reqFn = reqBody.id ? this.update : this.create;
    return reqFn(reqBody);
  }

  @PostMapping('/add')
  async create(reqBody: SubsidiaryVO): Promise<Boolean> {
    return true;
  }

  @PostMapping('/edit')
  async update(reqBody: SubsidiaryVO): Promise<Boolean> {
    return true;
  }

}