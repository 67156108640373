import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_date_picker, {
      modelValue: _ctx.selectDate,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectDate) = $event)),
      style: _normalizeStyle({ width: typeof _ctx.dateWidth === 'string' ? _ctx.dateWidth : _ctx.dateWidth + 'px' }),
      type: _ctx.datePickerType,
      "range-separator": "-",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      clearable: _ctx.clearable,
      shortcuts: _ctx.needShortcuts ? _ctx.shortcuts : [],
      onChange: _ctx.dateChange,
      disabledDate: _ctx.pickerOptions,
      "default-time": _ctx.defaultTime
    }, null, 8, ["modelValue", "style", "type", "clearable", "shortcuts", "onChange", "disabledDate", "default-time"])
  ]))
}