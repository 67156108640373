import { ContainerModule, interfaces } from "inversify";

export const mgrControllerRegistryModule = new ContainerModule(
  (
    bind: interfaces.Bind,
  ) => {
    const controllersContext = require.context('@', true, /\.Controller\.ts$/);
    controllersContext.keys().forEach((key: string) => {
      const controllerModule = controllersContext(key);
      const controllerClass = controllerModule.default;
      bind(controllerClass).toSelf();
    });
  }
);
