import jquery from "jquery"; //加上这句话
import lodash from "lodash";
import moment from "moment";
import { Plugin } from "vue";
import { ElMessage, ElNotification } from "element-plus";

export const miscVueInitPlugin: Plugin = (app) => {
  app.config.globalProperties.$moment = moment;
  app.config.globalProperties.$_ = lodash;
  app.config.globalProperties.$$ = jquery;
  app.config.globalProperties.$Message = ElMessage;
  app.config.globalProperties.$Notification = ElNotification;
};

declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
    $_: any;
    $$: any;
    $moment: any;
    $Message: typeof import("element-plus")["ElMessage"];
    $Notification: typeof import("element-plus")["ElNotification"];
  }
}
